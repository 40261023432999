.train-index {
    overflow: hidden;
    width: 100%;
    height: 100px; 
    position: relative;
    
  }
  
  .train-container {
    display: flex;
    width: 100%; 
    animation: train-moving 10s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .train-container img {
    height: 100px; /* Điều chỉnh chiều cao nếu cần */
    width: auto; /* Điều chỉnh kích thước nếu cần */
  }
  
  @keyframes train-moving {
    0% {
      transform: translateX(-100%); 
    }
    100% {
      transform: translateX(100%); 
    }
  }
  