/* Main */
:root {
  --color-red: #ec2d3f;
  --color-medium-red: #cc2c32;
  --color-dark-red: #c31829;
  --color-green: #28a745;
  --color-dark-green: #207d36;
  --color-black: #212529;
  --color-gray: #6c757d;
  --background-static: #37b547;
  --background-hover: #eceb1b;
  --color-static: #000;
  --color-hover: #ec2d3f;
}

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

body {
  font-size: 14px;
  font-family: regular;
  background: url(../src/assets/image/bg_kid.jpg);
}

.padding-main {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 991px) {
  .padding-main {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .image-post {
    height: 145px !important;
  }

  .padding-main {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

/* Toc */
a.mucluc-dropdown-list_button {}

a.mucluc-dropdown-list_button:before {
  content: "\f03a";
  font-family: "FontAwesome";
  font-style: normal;
  font-weight: 400;
  background: #fff;
  color: #006acb !important;
  font-size: 20px;
  width: 35px;
  height: 35px;
  display: inline-block;
  line-height: 38px;
  text-align: center;
  border: 1px solid #ddd;
}

.meta-toc.fiedx {
  position: fixed;
  left: 5px;
  top: 200px;
  z-index: 111;
}

.meta-toc .mucluc-dropdown-list_button {
  display: none;
}

.meta-toc.fiedx .mucluc-dropdown-list_button {
  display: block;
}

.meta-toc:not(.fiedx) .box-readmore {
  display: block !important;
}

.box-readmore {
  padding: 10px;
  background-color: #f5f5f5;
  width: 100%;
  margin-top: 2px;
  border: 1px solid #dddddd;
  font-size: 13px;
  margin-bottom: 10px;
}

.meta-toc.fiedx .box-readmore {
  width: 350px;
  display: none;
}

.box-readmore li ul>li {
  margin: 0;
  margin-bottom: 8px;
}

.box-readmore li ul>li:before {
  content: counters(item, ".") " ";
}

.box-readmore ul {
  list-style-type: none;
  counter-reset: item;
  margin-bottom: 0px;
  padding-left: 0px !important;
  margin-top: 8px;
}

.box-readmore ul li {
  display: table;
  counter-increment: item;
  margin: 0 0 5px 0;
}

.box-readmore ul li a {}

.box-readmore ul li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 5px;
}

.box-readmore ul li a {
  color: #000 !important;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

.box-readmore ul li li a {
  font-weight: 400;
}

.box-readmore ul li a:hover {
  color: #767676;
}

.opacity-0 {
  opacity: 0;
}

/* popup  */
.popup-custom .modal-content {
  border-radius: 0px;
  border: 0px;
}

.popup-custom .modal-content .modal-body {
  padding: 10px;
}

.popup-custom .close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  z-index: 2;
  color: #888888;
  cursor: pointer;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  opacity: 1 !important;
}

#popup-quickview .modal-dialog {
  max-width: 1140px;
}

.footer-copyright a {
  color: #fff;
}

.row-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.row-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.row-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.col-30 {
  padding-left: 15px;
  padding-right: -15px;
}

.col-20 {
  padding-left: 10px;
  padding-right: 10px;
}

.mg-col-10 {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-top-bottom {
  padding: 2.5rem 0;
}

.pad-top {
  padding-top: 2.5rem;
}

.pad-bottom {
  padding-bottom: 2.5rem;
}

.mg-top {
  margin-top: 2.5rem;
}

.mg-bottom {
  margin-bottom: 2.5rem;
}

.hover_xemthem {
  transition: 0.4s;
}

.hover_xemthem:hover {
  letter-spacing: 1px;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

a,
input,
textarea {
  outline: none;
  padding: 0px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.w-clear:after {
  display: block;
  content: "";
  clear: both;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.none {
  display: none;
}

.hidden-seoh {
  visibility: hidden;
  height: 0px;
  margin: 0px;
  overflow: hidden;
}

.wrap-content {
  max-width: 1200px;
  margin: auto;
  width: calc(100% - 30px);
}

.fix_head {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 999 !important;
}

.pd-l-10 {
  padding-left: -10px;
}

.pd-r-10 {
  padding-right: -10px;
}

.mg-l-10 {
  margin-left: -10px;
}

.mg-r-10 {
  margin-right: -10px;
}

.mg-b-20 {
  margin-bottom: 20px;
}

.title-detail-main {
  color: #252525;
  font-size: 23px;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

/* General */
.title-main {
  margin-bottom: 60px;
  text-align: center;
}

.ykien-index .title-main {
  margin-bottom: 30px;
}

.ykien-index {
  padding: 60px 0;
  display: contents
}

.title-main p {
  font-size: 42px;
  font-family: balloon;
  color: #1e4096;
  line-height: 35px;
}

p.slogan-from {
  font-size: 15px;
  font-family: regular;
  color: #000;
}

.title-main span {
  display: block;
  color: #37b547;
  text-align: center;
  font-family: Baloo;
  font-size: 42px;
  text-transform: uppercase;
  line-height: 45px;
}

.title-main .color2 {
  color: #f4941b;
}

.time-main {
  margin-bottom: 0.75rem;
  color: #999999;
}

.time-main i {
  vertical-align: top;
  margin: 3px 7px 0px 0px;
}

.time-main span {
  vertical-align: top;
  display: inline-block;
}

.share {
  padding: 17px 15px 10px 15px;
  line-height: normal;
  background: rgba(128, 128, 128, 0.15);
  margin-top: 15px;
  border-radius: 5px;
}

.share b {
  display: block;
  margin-bottom: 5px;
}

.control-owl {
  position: absolute;
  width: 100%;
  z-index: 2;
  left: 0px;
  top: calc(50% - 45px / 2);
}

.control-owl button {
  opacity: 0.5;
  top: 0px;
  position: absolute;
  outline: none;
  border: 0px;
  padding: 0px;
  margin: 0px;
  display: block;
  cursor: pointer;
  color: #ffffff;
  width: 45px;
  height: 45px;
  text-align: center;
  font-size: 25px;
  margin: 0px;
  background-color: #000000;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.control-owl button:hover {
  opacity: 1;
}

.control-owl button.owl-prev {
  left: 20px;
}

.control-owl button.owl-next {
  right: 20px;
}

.custom-form-row {
  margin-right: -10px;
  margin-left: -10px;
}

.custom-form-row>.col,
.custom-form-row>[class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

.text-sm {
  font-size: 0.875rem !important;
}

/* Lazyload */
img.lazy {
  opacity: 0;
}

img:not(.initial) {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

img.initial,
img.loaded,
img.error {
  opacity: 1;
}

img:not([src]) {
  visibility: hidden;
}

/* User */
.wrap-user {
  width: 100%;
  max-width: 550px;
  margin: auto;
  border: 1px solid #bce8f1;
  border-radius: 5px;
}

.title-user {
  background-color: #d9edf7;
  padding: 12px 15px;
  border-radius: 5px 5px 0px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.title-user span {
  color: #31708f;
}

.title-user a {
  color: #0056b3;
  font-size: 12px;
}

.form-user {
  padding: 20px 15px;
}

.input-user {
  margin-bottom: 0.75rem;
}

.input-user input {
  font-size: 14px;
}

.button-user {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.button-user input {
  font-size: 14px;
}

.checkbox-user,
.radio-user {
  cursor: pointer;
}

.checkbox-user input {
  top: calc(50% - 1.25rem / 2);
  height: auto;
}

.checkbox-user label {
  font-size: 14px;
  cursor: pointer;
}

.checkbox-user label::before,
.checkbox-user label::after {
  top: calc(50% - 1rem / 2);
}

.radio-user {
  margin-right: 1rem;
}

.radio-user:last-child {
  margin-right: 0px;
}

.radio-user label::before,
.radio-user label::after {
  top: calc(50% - 1rem / 2);
}

.note-user {
  font-size: 14px;
  border-top: 1px solid #ddd;
  padding-top: 0.5rem;
}

/* Cart */
#popup-cart .modal-title {
  text-transform: uppercase;
}

#popup-cart .modal-body .wrap-cart {
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0px;
}

#popup-cart .modal-footer {
  padding: 0.75rem 0 0 0;
  border-top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.buymore-cart {
  color: #ec2d3f;
  cursor: pointer;
  margin: 0px;
}

.buymore-cart i {
  margin-right: 2px;
  margin-top: 5px;
  vertical-align: top;
  font-size: 12px;
}

.wrap-cart {
  padding: 20px 15px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.top-cart {
  border-right: 1px solid #eee;
}

@media only screen and (max-width: 991px) {
  .top-cart {
    margin-bottom: 2rem;
  }
}

.procart {
  padding: 20px 10px;
  border: 1px solid #eee;
  border-top: 0px;
}

.pic-procart {
  text-align: center;
}

.pic-procart img {
  max-height: 100px;
  height: inherit;
}

.pic-procart .del-procart {
  color: #999;
  font-size: 12px;
  display: block;
  margin-top: 5px;
  cursor: pointer;
}

.pic-procart .del-procart:hover {
  color: red;
}

.name-procart a {
  font-size: 14px;
  color: #000;
  display: block;
}

@media only screen and (max-width: 575px) {
  .name-procart a {
    font-size: 13px;
  }
}

.name-procart a:hover {
  color: #ec2d3f;
}

.properties-procart {
  display: block;
}

.properties-procart p {
  display: inline-block;
  margin-bottom: 0px;
}

.properties-procart p:first-child {
  margin-right: 10px;
}

.properties-procart p strong {
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .quantity-procart {
    text-align: right;
  }
}

.quantity-procart p {
  margin-bottom: 0px;
}

.quantity-counter-procart {
  border: 1px solid #dfdfdf;
  width: 100%;
  height: 30px;
  line-height: normal;
  text-align: center;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.counter-procart {
  background-color: transparent;
  border: 0px;
  width: 30%;
  color: #333;
  outline: none;
  cursor: pointer;
  font-size: 23px;
}

.counter-procart:hover,
.counter-procart:focus {
  color: #ec2d3f;
}

.counter-procart:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.counter-procart:disabled:hover {
  color: #ccc;
  cursor: not-allowed;
}

.counter-procart-minus {
  line-height: 26px;
}

.counter-procart-plus {
  line-height: 24px;
}

.quantity-counter-procart .quantity-procart {
  width: 40%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0px;
  border-right: 1px solid #dfdfdf;
  border-left: 1px solid #dfdfdf;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px;
  color: #333;
  text-align: center;
}

.price-procart {
  width: 20%;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .price-procart {
    display: none;
  }
}

.price-procart-rp {
  display: none;
  width: 100%;
  margin-bottom: 10px;
  font-size: 13.5px;
}

@media only screen and (max-width: 767px) {
  .price-procart-rp {
    display: block;
  }
}

.price-new-cart {
  color: #ec2d3f;
  margin-bottom: 0px;
}

.price-old-cart {
  color: #999;
  text-decoration: line-through;
}

.procart-label {
  border: 0px;
  background: #eee;
  padding: 10px;
  margin: 0px;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .procart-label {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) {
  .procart-label .quantity-procart p:first-child {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .procart-label .quantity-procart p:first-child {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .procart-label .quantity-procart p:last-child {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .procart-label .quantity-procart p:last-child {
    display: block;
  }
}

.money-procart {
  margin-top: 15px;
}

.total-procart {
  background: #eee;
  padding: 7px 10px;
  border: 1px solid #dddfe2;
  margin-bottom: -1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.total-procart p {
  margin-bottom: 0px;
  font-size: 15px;
}

.total-procart p:last-child {
  color: #ec2d3f;
}

.section-cart {
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
}

.title-cart {
  font-size: 15px;
  text-transform: uppercase;
  color: #000;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.information-cart {
  margin-bottom: 1.5rem;
}

.input-cart {
  margin-bottom: 0.5rem;
  position: relative;
}

.input-cart textarea {
  resize: none;
  height: 100px;
}

.input-cart select {
  cursor: pointer;
}

.payments-cart {
  text-align: left;
  margin-bottom: 5px;
  min-height: auto;
  padding-left: 0;
  margin-left: 20px;
  position: relative;
}

.payments-cart:last-child {
  margin-bottom: 0px;
}

.payments-cart input {
  top: 10px;
  /* opacity: 0; */
  position: absolute;
}

.payments-label {
  cursor: pointer;
  background: #eee;
  display: block;
  padding: 10px 15px;
  border-radius: 3px;
  margin-bottom: 0px;
  color: #313131 !important;
}

.payments-label::before,
.payments-label::after {
  top: calc(50% - 1rem / 2);
}

.payments-label.active {
  background: var(--background-static);
  color: #ffffff !important;
}

.payments-info {
  opacity: 0;
  visibility: hidden;
  height: 0px;
  overflow: hidden;
  background: #f5efef;
  border-radius: 5px;
}

.payments-info p {
  margin-bottom: 1em;
}

.payments-info.active {
  padding: 10px 15px;
  margin-top: 5px;
  opacity: 1;
  visibility: visible;
  height: auto;
}

.btn-cart {
  text-transform: capitalize;
}

.empty-cart {
  padding: 25px 30px;
  text-align: center;
}

.empty-cart i {
  color: red;
  font-size: 70px;
}

.empty-cart p {
  margin: 15px 0px;
  color: #333;
  font-size: 18px;
}

/* Comment */
.comment-title {
  font-size: 18px;
}

.comment-show {
  display: block !important;
}

.comment-point {
  font-size: 47px;
  color: #fe302e;
}

.comment-star {
  font-size: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0.5rem;
}

.comment-star span {
  display: block;
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}

.comment-star i {
  display: inline-block;
  margin: 0.1rem;
  color: #b8b8b8;
  text-rendering: auto;
  font-size: 20px;
}

.comment-star i:nth-child(1) {
  margin-left: 0;
}

.comment-star span i {
  color: #ffc120;
}

.comment-progress {
  width: 100%;
  margin-bottom: 0.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.comment-progress:last-child {
  margin-bottom: 0px;
}

.comment-progress .progress {
  height: 12px;
  margin-right: 10px;
  margin-left: 5px;
  width: calc(100% - 90px);
  border-radius: 10px;
}

.comment-progress .progress-bar#has-rate {
  background-color: #23b445;
}

.comment-progress .progress-num {
  font-size: 15px;
  width: 25px;
  text-align: center;
  color: #787878;
}

.comment-progress .progress-total {
  font-weight: 600;
  text-align: left;
  color: #787878;
  width: 50px;
}

.comment-write {
  display: none;
}

.review-rating-star {
  font-size: 0;
}

.review-rating-star-icon .fa {
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  margin: 0.1rem;
  font-size: 21px;
  cursor: pointer;
  color: #f0c808;
}

.review-rating-star-icon .fa.star-empty {
  color: #dedddc;
}

.review-rating-star-icon .fa.star-not-empty {
  color: #f0c808;
}

.review-rating-star-icon .fa:nth-child(1) {
  margin-left: 0;
}

.review-fileuploader {
  padding: 20px 15px;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
  border: 2px dashed #c2cdda;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.review-fileuploader .review-fileuploader-text {
  color: #90a0bc;
}

.review-file-uploader .fileuploader {
  padding: 0px;
  margin: 0px;
  background: transparent;
}

.review-file-uploader .fileuploader-items .fileuploader-items-list {
  margin: 1rem -5px 0 -5px;
}

.review-file-uploader .fileuploader-items .fileuploader-items-list:after {
  display: block;
  content: "";
  clear: both;
}

.review-file-uploader .fileuploader-items .fileuploader-items-list .fileuploader-item {
  padding: 0px;
  width: calc(100% / 7 - 10px);
  float: left;
  margin: 0 5px 5px 5px;
  border-bottom: 0px;
}

.review-file-uploader .fileuploader-items .fileuploader-items-list .fileuploader-item .columns {
  display: block;
}

.review-file-uploader .fileuploader-items .fileuploader-items-list .fileuploader-item .column-thumbnail {
  width: auto;
  height: auto;
}

.review-file-uploader .fileuploader-items .fileuploader-items-list .fileuploader-item .column-thumbnail .fileuploader-item-image {
  height: auto;
  padding: 50% 0;
}

.review-file-uploader .fileuploader-items .fileuploader-items-list .fileuploader-item .column-thumbnail .fileuploader-item-image img {
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transform: none;
  transform: none;
}

.review-file-uploader .fileuploader-items .fileuploader-items-list .fileuploader-item .column-title {
  padding: 0px;
  margin-top: 0.5rem;
  text-align: center;
  display: none;
}

.review-file-uploader .fileuploader-items .fileuploader-items-list .fileuploader-item .column-actions {
  margin: 0px;
  position: absolute;
  top: 0px;
  right: -5px;
}

.review-file-uploader .fileuploader-items .fileuploader-items-list .fileuploader-item .column-infos input {
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
}

.review-poster-video-avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.review-poster-video-avatar .avatar-detail {
  width: 95px;
  height: 95px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.review-poster-video-avatar .avatar-detail img {
  max-width: 95px;
  max-height: 95px;
}

#review-content {
  height: 350px;
}

.carousel-comment-media .carousel-control a {
  opacity: 1;
  width: 45px;
  height: 45px;
  top: calc(50% - 45px / 2);
  bottom: inherit;
}

.carousel-comment-media .carousel-control a span {
  display: block;
  cursor: pointer;
  color: #222222;
  width: 45px;
  height: 45px;
  padding: 5px;
  text-align: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.carousel-comment-media .carousel-control a.carousel-control-prev {
  left: 20px;
}

.carousel-comment-media .carousel-control a.carousel-control-prev span {
  padding-right: 9px;
}

.carousel-comment-media .carousel-control a.carousel-control-next {
  right: 20px;
}

.carousel-comment-media .carousel-control a.carousel-control-next span {
  padding-left: 8px;
}

.carousel-comment-media .carousel-indicators {
  position: static;
  margin: 0 0 1rem 0;
}

.carousel-comment-media .carousel-indicators li {
  width: auto;
  max-width: 60px;
  height: auto;
  text-indent: inherit;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin: 0px 0px 3px 0px;
  border: 2px solid transparent;
  opacity: 1;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.carousel-comment-media .carousel-indicators li.active {
  border-color: #ffc120;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.carousel-comment-media .carousel-inner {
  max-width: 550px;
  -webkit-box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  margin: auto;
}

.carousel-comment-media .carousel-inner .carousel-lists .carousel-comment-media-item-video #file-video {
  max-width: 100%;
  width: 100%;
  height: 500px;
  vertical-align: top;
  display: inline-block;
  background-color: #000000;
}

#form-reply {
  display: none;
}

.comment-item {
  margin-bottom: 3rem;
}

@media only screen and (min-width: 992px) {
  .comment-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.comment-item-poster {
  width: 200px;
  margin-right: 30px;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .comment-item-poster {
    width: auto;
    margin-right: 0px;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.comment-item-letter {
  border-radius: 50%;
  background-color: #d3d2d3;
  color: #919090;
  width: 65px;
  height: 65px;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
  line-height: 67px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 991px) {
  .comment-item-letter {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    margin-bottom: 0;
  }
}

.comment-item-name {
  margin-bottom: 0.25rem;
  text-transform: capitalize;
}

@media only screen and (max-width: 991px) {
  .comment-item-name {
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.comment-item-posttime {
  font-size: 12px;
  color: #999999;
}

.comment-item-information {
  width: calc(100% - 230px);
}

@media only screen and (max-width: 991px) {
  .comment-item-information {
    width: 100%;
  }
}

.comment-item-star {
  float: left;
  margin-right: 10px;
}

.comment-item-star i {
  font-size: 17px;
}

.comment-item-title {
  font-size: 15px;
  font-weight: 500;
}

.comment-replies {
  position: relative;
  background: #f8f9fa;
  border: 1px solid #dfdfdf;
  padding: 1.75rem 1.5rem;
}

.comment-replies:after {
  content: "";
  position: absolute;
}

.comment-replies:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: -13px;
  left: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #f8f9fa;
}

.comment-replies:after {
  top: -14px;
  left: 9px;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 14px solid #dfdfdf;
}

.comment-replies-item {
  margin-bottom: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.comment-replies-item:last-child {
  margin-bottom: 0px;
}

.comment-replies-letter {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  line-height: 45px;
  padding-left: 1px;
  font-size: 14px;
  border-radius: 50%;
  background-color: #e5e3e5;
  color: #919090;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  text-align: center;
  margin-top: 0.125rem;
}

.comment-replies-letter.admin {
  position: relative;
  background-color: #189eff;
  text-indent: 9999px;
  overflow: hidden;
}

.comment-replies-letter.admin:before {
  content: "QTV";
  text-indent: 0px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.comment-replies-info {
  width: calc(100% - 55px);
}

.comment-replies-name {
  font-weight: 500;
  margin-bottom: 0.125rem;
}

.comment-replies [class*="btn-load-more-"] {
  border: 0px;
  outline: none;
  padding: 0px;
  background: transparent;
}

.comment-media-play {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.comment-media-play svg {
  -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
}

.comment-media-play svg .comment-media-play-stroke-dotted {
  opacity: 0;
  stroke-dasharray: 4, 5;
  stroke-width: 1px;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: spin 4s infinite linear;
  animation: spin 4s infinite linear;
  -webkit-transition: opacity 1s ease, stroke-width 1s ease;
  transition: opacity 1s ease, stroke-width 1s ease;
}

.comment-media-play svg .comment-media-play-stroke-solid {
  stroke-dashoffset: 0;
  stroke-dasharray: 300;
  stroke-width: 4px;
  -webkit-transition: stroke-dashoffset 1s ease, opacity 1s ease;
  transition: stroke-dashoffset 1s ease, opacity 1s ease;
}

.comment-media-play svg .comment-media-play-icon {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 200ms ease-out;
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.comment-media-play:hover svg .comment-media-play-stroke-dotted {
  stroke-width: 4px;
  opacity: 1;
}

.comment-media-play:hover svg .comment-media-play-stroke-solid {
  opacity: 0;
  stroke-dashoffset: 300;
}

.comment-media-play:hover svg .comment-media-play-icon {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

/* head */
.head {
  color: #ffffff;
  background: var(--background-static);
}

.head p {
  line-height: 45px;
}

.head p img {
  /* margin-top: 15px; */
  margin-right: 8px;
}

.head .head-top {
  color: #ffffff;
  background-color: var(--background-static);
}

.slogan-head {
  height: 40px;
  display: flex;
  align-items: center;
}

.info-head {
  margin-bottom: 0;
}

.info-head i {
  vertical-align: top;
  margin: 3px 5px 0px 0px;
  font-size: 16px;
}

.head .head-bottom .wrap-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.social-head li {
  width: 30px;
}

.head-bottom .wrap-content {
  padding: 10px 0;
}

.user-head a {
  color: #ffffff;
  padding-right: 9px;
  margin-right: 7px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
}

.user-head a:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 8px;
  background-color: #ffffff;
  top: calc(50% - 7px / 2);
  right: 0px;
  opacity: 0.7;
}

.user-head a:last-child {
  padding-right: 0px;
  margin-right: 0px;
}

.user-head a:last-child:after {
  display: none;
}

/* Menu */
.menu {
  background-color: #fff;
  position: relative;
  z-index: 10;
  padding-bottom: 10px;
}

.sticky-menu {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: white;
}

.fix_head .menu:after {
  display: none;
}

.fix_head .menu {
  padding-bottom: 0;
}

.menu:after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 25px;
  background: url(../src/assets/image/des_menu2.png) repeat-x bottom left;
}

.menu-index.scrolled:after {
  background: none;
}

.menu-index.scrolled {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media only screen and (max-width: 991px) {
  .menu {
    display: none;
  }
}

.menu ul {
  width: 100%;
  padding: 0px;
  margin: auto;
  list-style: none;
}

.menu ul.menu-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu ul li {
  position: relative;
  z-index: 1;
}

.menu ul li.menu-line {
  width: 1px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.menu ul li a {
  display: block;
  position: relative;
  font-size: 18px;
  color: #000;
  /* padding: 15px; */
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  z-index: 9;
}

.fix_head .menu-main li a img {
  max-height: 120px;
}

.menu-main li a img {
  max-height: 120px;
}

.menu ul.menu-main>li>a:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 90px;
  height: 13px;
  background: transparent;
}

.menu ul.menu-main>li>a.active:after,
.menu ul.menu-main>li>a:hover:after {
  background: url(../src/assets/image/des_menu_item.png);
}

.menu ul.menu-main>li>a.active {
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.menu ul.menu-main>li:first-child>a:after {
  display: none;
}

.menu ul li a:hover {
  color: #37b547;
}

.menu ul li a.active {
  font-family: medium;
  color: #37b547;
}

.menu ul li a.has-child {
  padding-right: 16px;
  margin-right: 8px;
}

.menu ul li a.has-child:after {
  content: "";
  position: absolute;
  bottom: calc(50% - 4px / 2);
  right: 5px;
  width: 5px;
  height: 5px;
  border: 1px solid #fafafa;
  border-top: 0px;
  border-left: 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu ul li ul {
  position: absolute;
  min-width: 250px;
  /* margin-top: -5px; */
  /* padding-top: 0.75rem; */
  /* padding-bottom: 0.75rem; */
  background-color: #fafafa;
  border-radius: 0.25rem;
  -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15);
  -webkit-transform: perspective(600px) rotateX(-90deg);
  transform: perspective(600px) rotateX(-90deg);
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  opacity: 0;
  visibility: hidden;
}

.menu ul li:hover>ul {
  -webkit-transform: perspective(600px) rotateX(0);
  transform: perspective(600px) rotateX(0);
  transform-origin: 0 0 0;
  -webkit-transform-origin: 0 0 0;
  opacity: 1;
  visibility: visible;
}

.menu ul li ul li {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.menu ul li ul li a {
  font-size: 14px;
  color: #313131;
  border-bottom: 1px solid #ececec;
  padding: 10px 0px 9px 0px;
  text-transform: capitalize;
}

.menu ul li ul li a.has-child {
  margin-right: 0px;
}

.menu ul li ul li a.has-child:after {
  border-color: #313131;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu ul li ul li a.active {
  color: #ec2d3f;
}

.menu ul li ul li a.active.has-child:after {
  border-color: #ec2d3f;
}

.menu ul li ul li:last-child>a {
  border-bottom: 0px;
}

.menu ul li ul li:hover>a {
  color: #ec2d3f;
}

.menu ul li ul li:hover>a.has-child:after {
  border-color: #ec2d3f;
}

.menu ul li ul li ul {
  top: 0;
  left: 100%;
  margin-top: 0px;
}

/* Search */
.search {
  width: 230px;
  background: #ffffff;
}

.search p {
  float: left;
  width: 35px;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  text-align: center;
  margin: 0px;
  color: #6c757d;
  font-size: 16px;
}

.search input {
  width: calc(100% - 35px);
  height: 35px;
  float: left;
  outline: none;
  padding: 0px;
  border: 0px;
  background: transparent;
  text-indent: 10px;
  font-size: 12px;
}

.search input::-webkit-input-placeholder {
  color: #313131;
}

.search input:-moz-placeholder {
  color: #313131;
}

.search input::-moz-placeholder {
  color: #313131;
}

.search input:-ms-input-placeholder {
  color: #313131;
}

/* Mmenu */
.menu-res {
  z-index: 100;
  background: #fff;
  position: relative;
  display: none;
}


@media only screen and (max-width: 991px) {
  .menu-res {
    display: block;
  }
}

.menu-bar-res {
  /* height: 55px; */
  padding: 0px 15px;
}

.logo-res img {
  max-height: 70px;
}

#menu {
  display: none;
}

#hamburger {
  display: block;
  width: 30px;
  height: 23px;
  position: relative;
}

#hamburger:before,
#hamburger:after,
#hamburger span {
  background: var(--background-static);
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0px;
}

#hamburger:before {
  top: 0px;
}

#hamburger span {
  top: 10px;
}

#hamburger:after {
  top: 20px;
}

#hamburger:before,
#hamburger:after,
#hamburger span {
  -webkit-transition: none 0.5s ease 0.5s;
  transition: none 0.5s ease 0.5s;
  -webkit-transition-property: transform, top, bottom, left, opacity;
  transition-property: transform, top, bottom, left, opacity;
}

.mm-wrapper_opening #hamburger:before,
.mm-wrapper_opening #hamburger:after {
  top: 10px;
}

.mm-wrapper_opening #hamburger span {
  left: -50px;
  opacity: 0;
}

.mm-wrapper_opening #hamburger:before {
  transform: rotate(45deg);
}

.mm-wrapper_opening #hamburger:after {
  transform: rotate(-45deg);
}

.mm-menu_opened {
  display: block !important;
}

/* Search Responsive */

.wrap-home,
.footer {
  overflow: hidden;
}

.search-res {
  position: relative;
}

.search-res .icon-search {
  width: 40px;
  height: 40px;
  cursor: pointer;
  text-align: center;
  line-height: 42px;
  color: #37b547;
  background: #b4e8bb;
  border-radius: 50%;
  font-size: 17px;
  margin: 0px;
}

.search-res .icon-search.active {
  color: var(--background-static);
  background: #ffffff;
  border-radius: 100%;
}

.search-res .search-grid {
  position: absolute;
  top: 50px;
  right: 0px;
  width: 0px;
  height: 40px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid var(--background-static);
  z-index: 2;
  opacity: 0;
  border-radius: 25px;
  line-height: normal;
}

.search-res .search-grid p {
  float: left;
  width: 35px;
  height: 38px;
  cursor: pointer;
  outline: none;
  border: none;
  margin: 0px;
  font-size: 17px;
  display: block;
  color: var(--background-static);
  line-height: 40px;
  text-align: center;
}

.search-res .search-grid input {
  width: calc(100% - 35px);
  float: right;
  line-height: 38px;
  outline: none;
  border: none;
  color: var(--background-static);
}

.search-res .search-grid input::-webkit-input-placeholder {
  color: #313131;
}

.search-res .search-grid input:-moz-placeholder {
  color: #313131;
}

.search-res .search-grid input::-moz-placeholder {
  color: #313131;
}

.search-res .search-grid input:-ms-input-placeholder {
  color: #313131;
}

/* Slideshow */
.slideshow {
  position: relative;
}

.slideshow-item {
  display: block;
  cursor: pointer;
}

.slideshow:hover .control-slideshow {
  opacity: 1;
}

.control-slideshow {
  opacity: 0;
}

.info-slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.name-slide {
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
}

.desc-slide {
  font-size: 15px;
  color: #fff;
  width: 500px;
  margin: 20px 0;
}

.views-more-slide {
  background-color: var(--background-static);
  width: 120px;
  height: 40px;
  color: #fff;
  font-size: 15px;
}

.box-slide.slick-current.slick-active .name-slide {
  animation: fadeInUp;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.box-slide.slick-current.slick-active .desc-slide {
  animation: fadeInUp;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.box-slide.slick-current.slick-active .views-more-slide {
  animation: scaleLarge;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

.slide-text .slick-prev,
.slide-text .slick-next {
  width: 40px;
  height: 50px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
}

.slide-text .slick-prev {
  left: 5px;
}

.slide-text .slick-next {
  right: 5px;
}

.slide-text .slick-prev:before {
  font-family: "FontAwesome";
  font-weight: 400;
  content: "\f053";
}

.slide-text .slick-next:before {
  font-family: "FontAwesome";
  font-weight: 400;
  content: "\f054";
}

.slide-text .slick-dots {
  bottom: 20px;
}

.slide-text .slick-dots li {
  margin: 0;
}

.slide-text .slick-dots li button:before {
  color: #fff;
  font-size: 15px;
}

/* .slick-slider .slick-vertical .slick-initialized .slick-list {
  height: 204px !important;
} */
.slick-slider .slick-track,
.slick-slider .slick-list {
  min-height: 204px;
}

/* Partner */
.control-partner button {
  background-color: transparent;
  color: #222222;
  opacity: 1;
  width: 25px;
  font-size: 23px;
}

.control-partner button:hover {
  opacity: 0.7;
}

.control-partner button.owl-prev {
  left: 0px;
}

.control-partner button.owl-next {
  right: 0px;
}

.wrap-partner {
  margin-bottom: 2rem;
}

.wrap-partner .wrap-content {
  position: relative;
}

.partner {
  display: block;
  border: 1px solid #eee;
  max-width: 175px;
  margin: 0px auto;
}

/* Breadcrumb */
.breadCrumbs {
  background-color: #eee;
}

.breadCrumbs .wrap-content {
  padding: 0.75rem 0;
}

.breadCrumbs .wrap-content .breadcrumb {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-radius: 0;
}

.breadCrumbs .wrap-content .breadcrumb-item a {
  color: #212529;
}

.breadCrumbs .wrap-content .breadcrumb-item.active a {
  color: #6c757d;
}

/* Intro */
.wrap-intro {
  background-color: #eee;
}

.news-intro:before {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% - 50px);
  top: 10px;
  left: 90px;
  background-color: #cacaca;
}

.news-scroll:before,
.news-scroll:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 85px;
  border-radius: 100%;
  background-color: #cacaca;
}

.news-scroll:before {
  top: 10px;
}

.news-scroll:after {
  bottom: 40px;
}

.news-control {
  left: 85px;
  font-size: 13px;
  opacity: 0.5;
  z-index: 1;
  cursor: pointer;
}

.news-control:hover {
  opacity: 1;
  color: #ec2d3f;
}

.news-control#up {
  top: -10px;
}

.news-control#down {
  bottom: 20px;
}

.news-shadow {
  padding-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.news-shadow .news-shadow-time {
  font-size: 13px;
  width: 75px;
  margin-right: 45px;
}

.news-shadow .news-shadow-time:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: calc(50% - 20px / 2);
  right: -26px;
  background-repeat: no-repeat;
  background-position: center;
  /* background-image: url(../src/assets/image/pattern-news.png); */
}

.news-shadow .news-shadow-article {
  width: calc(100% - 120px);
  padding: 14px 15px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #cecabb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.news-shadow .news-shadow-article:before {
  content: "";
  position: absolute;
  top: calc(50% - 26px / 2);
  left: -13px;
  z-index: 0;
  border-top: 13px solid transparent;
  border-right: 13px solid #cecabb;
  border-bottom: 13px solid transparent;
}

.news-shadow .news-shadow-article:after {
  content: "";
  position: absolute;
  top: calc(50% - 24px / 2);
  left: -11px;
  z-index: 1;
  border-top: 12px solid transparent;
  border-right: 11px solid #ffffff;
  border-bottom: 12px solid transparent;
}

.news-shadow .news-shadow-article .news-shadow-image {
  margin-right: 10px;
  width: 90px;
}

.news-shadow .news-shadow-article .news-shadow-info {
  width: calc(100% - 100px);
}

.news-shadow .news-shadow-article .news-shadow-info .news-shadow-name {
  height: 36px;
  font-size: 15px;
  font-weight: 700;
}

.news-shadow .news-shadow-article .news-shadow-info .news-shadow-name a {
  color: #222222;
}

.news-shadow .news-shadow-article .news-shadow-info .news-shadow-name a:hover {
  color: #ec2d3f;
}

.news-shadow .news-shadow-article .news-shadow-info .news-shadow-name a .text-split {
  -webkit-line-clamp: 2;
}

.news-shadow .news-shadow-article .news-shadow-info .news-shadow-desc {
  height: 38px;
  font-size: 13px;
}

.news-shadow .news-shadow-article .news-shadow-info .news-shadow-desc.text-split {
  -webkit-line-clamp: 2;
}

/* Video */
.video {
  cursor: pointer;
  margin-bottom: 1rem;
}

.video .video-image {
  position: relative;
  margin-bottom: 0.75rem;
}

.video .video-image:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 35px;
  top: calc(50% - 50px / 2);
  left: calc(50% - 35px / 2);
  z-index: 1;
  background-repeat: no-repeat;
  background-image: url(../src/assets/image/play-ytb.png);
  /* background-image: url(../images/play.png); */
}

.video .video-name {
  text-align: center;
  font-size: 16px;
  color: #212529;
  margin-bottom: 0px;
}

/* Album */
.album {
  cursor: pointer;
}

.album .album-image {
  margin-bottom: 0.75rem;
}

.album .album-name {
  margin-bottom: 0px;
  text-align: center;
}

.album .album-name a {
  display: block;
  font-size: 16px;
  color: #212529;
  margin-bottom: 0px;
}

/* Brand */
.wrap-brand .wrap-content {
  position: relative;
}

.brand {
  display: block;
  border: 1px solid #eee;
  max-width: 160px;
  margin: 0px auto;
}

/* Product */
.wrap-product {
  margin-bottom: 50px;
}

.cart-product {
  margin-bottom: 0px;
}

.cart-product span {
  cursor: pointer;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
}

/* Product Detail */
.grid-pro-detail {
  margin-bottom: 3rem;
}

.left-pro-detail {
  position: relative;
  text-align: center;
  width: 45%;
}

.right-pro-detail {
  width: calc(55% - 40px);
}

.left-pro-detail .MagicZoom {
  border: 1px solid #eee;
  padding: 7px;
  border-radius: 5px;
  background-color: #ffffff;
}

.gallery-thumb-pro {
  position: relative;
  margin-top: 10px;
}

.owl-pro-detail {
  padding: 0;
}

.control-pro-detail button {
  background-color: transparent;
  color: #222222;
  opacity: 1;
  width: 25px;
  font-size: 23px;
}

.control-pro-detail button:hover {
  opacity: 0.7;
}

.control-pro-detail button.owl-prev {
  left: 0px;
}

.control-pro-detail button.owl-next {
  right: 0px;
}

.thumb-pro-detail {
  display: block !important;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
  background-color: #ffffff;
}

.thumb-pro-detail.mz-thumb.mz-thumb-selected {
  border-color: #cecfd2;
}

.thumb-pro-detail img {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-filter: brightness(100%) !important;
  filter: brightness(100%) !important;
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.title-pro-detail {
  text-transform: capitalize;
  font-size: 20px;
  display: block;
  font-weight: 700;
}

.comment-pro-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.social-plugin-pro-detail {
  margin-bottom: 1rem;
  margin-top: 0px !important;
}

.desc-pro-detail {
  margin-bottom: 1rem;
}

.attr-pro-detail {
  list-style: none;
  padding: 0px;
}

.attr-pro-detail li {
  margin-bottom: 0.5rem;
}

.attr-label-pro-detail {
  margin: 0px 5px 0px 0px;
}

.attr-content-pro-detail {
  display: inline-block;
  margin-bottom: 0px;
}

.brand-pro-detail {}

.brand-pro-detail a {
  background: var(--color-red);
  color: #fff;
  border-radius: 2px;
  padding: 2px 5px;
}

.price-new-pro-detail {
  font-weight: 700;
  font-size: 23px;
  color: var(--color-black);
}

.price-old-pro-detail {
  font-weight: 400;
  color: var(--color-gray);
  text-decoration: line-through;
  padding-left: 10px;
}

.color-pro-detail {
  cursor: pointer;
  vertical-align: top;
  position: relative;
  width: 48px;
  height: 32px;
  margin: 0 8px 5px 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border: 1px solid #dadada;
  transition: 0.3s;
  border-radius: 2px;
}

.size-pro-detail {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 48px;
  position: relative;
  margin: 0 8px 0 0;
  border: 1px solid #dadada;
  border-radius: 2px;
  font-size: 12px;
  transition: 0.3s;
}

.size-pro-detail.active,
.color-pro-detail.active {
  border-color: var(--color-dark-red);
}

.size-pro-detail.active:after,
.color-pro-detail.active:after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  /* background-image: url(../images/check-cart.png); */
}

.color-pro-detail input[type="radio"],
.size-pro-detail input[type="radio"] {
  display: none;
}

.quantity-pro-detail {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 45px;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #dfdfdf;
}

.quantity-pro-detail span {
  line-height: 25px;
  padding: 0px;
  width: 45px;
  height: 100%;
  color: #000;
  cursor: pointer;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.quantity-pro-detail span.quantity-plus-pro-detail {
  border-left: 0px;
}

.quantity-pro-detail span.quantity-minus-pro-detail {
  border-right: 0px;
}

.quantity-pro-detail input {
  height: 100%;
  border: none;
  width: calc(100% - 90px);
  text-align: center;
  font-size: 15px;
}

.cart-pro-detail {
  max-width: 400px;
}

.cart-pro-detail a {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  width: calc(100% / 2 - 5px);
  height: 45px;
  border-radius: 5px;
}

.cart-pro-detail a.addnow {
  background: #fff;
  color: var(--color-red);
}

.cart-pro-detail a.addnow:hover {
  color: #fff;
  background: var(--color-red);
}

.cart-pro-detail a.buynow {
  background-color: #333;
}

.cart-pro-detail a.buynow:hover {
  background-color: var(--color-dark-red);
  color: #fff;
}

.cart-pro-detail a i {
  vertical-align: top;
  margin-right: 8px;
  font-size: 24px;
  position: relative;
  top: -2px;
}

.tags-pro-detail a {
  float: left;
  font-size: 13px;
  padding-bottom: 0.375rem;
  margin: 0px 5px 5px 0px;
}

.tags-pro-detail a i {
  font-size: 11px;
  margin: 5px 5px 0px 0px;
}

.tabs-pro-detail {
  margin-top: 2rem;
}

.tabs-pro-detail .nav-tabs .nav-link {
  border-top-width: 3px;
  font-size: 13px;
  color: #333;
  text-transform: uppercase;
  font-weight: 500;
}

.tabs-pro-detail .nav-tabs .nav-link.active,
.tabs-pro-detail .nav-tabs .nav-item.show .nav-link {
  border-top-color: #555555;
}

/* News */

.pic-news {
  width: 210px;
}

.pic-news img {
  width: 100%;
}

.info-news {
  width: calc(100% - 210px);
  padding-left: 20px;
}

.name-news {
  color: #252525;
  font-size: 17px;
  line-height: normal;
  font-weight: 600;
}

.name-news.text-split {
  -webkit-line-clamp: 2;
}

.name-news:hover {
  color: var(--background-static);
}

.time-news {
  color: var(--color-gray);
  margin-bottom: 10px;
}

.desc-news {
  color: #676767;
  margin-top: 5px;
  line-height: 22px;
}

.news .news-name {
  margin-bottom: 0.5rem;
}

.news .news-name a {
  color: #333;
  font-size: 16px;
}

.news .news-name a.text-split {
  -webkit-line-clamp: 2;
}

.news .news-name a:hover {
  color: #ec2d3f;
}

.news .news-time {
  color: #84878a;
  margin-bottom: 0.25rem;
}

.news .news-desc {
  color: #333333;
  margin-top: 5px;
  line-height: 22px;
}

.othernews b {
  margin-bottom: 10px;
  font-size: 15px;
}

/*News orther*/
.othernews {
  position: sticky;
  top: 60px;
  z-index: 11;
}

.news-other {
  margin-bottom: 15px;
}

.pic-news-other {
  width: 40%;
}

.info-news-other {
  width: 60%;
  padding-left: 10px;
}

.name-news-other {
  color: #252525;
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
}

.name-news-other:hover {
  color: var(--background-static);
}

.name-news-other.text-split {}

/* Contact */
.form-floating.form-floating-cus>input {
  height: 45px;
  position: relative;
}

.form-floating.form-floating-cus>textarea {
  height: 100px;
}

.form-floating.form-floating-cus>label {
  padding: 10px 10px 10px 25px;
  color: var(--color-gray);
}

.contact-article {
  margin-bottom: 3rem;
}

.contact-input {
  position: relative;
  margin-bottom: 20px;
}

.contact-input input,
.contact-input textarea {
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
}

.contact-input input:focus,
.contact-input textarea:focus {
  box-shadow: none;
  outline: none;
}

.contact-input textarea {
  resize: none;
  height: 150px;
}

.contact-input .custom-file-label::after {
  content: attr(title);
}

.contact-map {
  position: relative;
  height: 500px;
}

.contact-map iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100% !important;
  height: 100% !important;
}

/* Newsletter */
.newsletter-slogan {
  margin-bottom: 0.5rem;
  color: var(--color-gray);
}

.form-index .form-newsletter {
  max-width: 500px;
  margin: 0 auto;
}

.newsletter-input {
  position: relative;
  height: 100%;
  width: 100%;
}

.form-floating.form-floating-cus {
  padding-bottom: 15px;
}

.newsletter-input input {
  border-radius: 0px;
  height: 100%;
  width: 100%;
}

.newsletter-button input[type="submit"] {
  background: #f4941b;
}

.newsletter-button input[type="submit"],
.newsletter-button input[type="reset"] {
  width: 200px !important;
  height: 46px;
  border-radius: 60px;
  border: 0;
  color: #fff;
  font-size: 18px;
  font-family: "Baloo";
  text-transform: uppercase;
}

.newsletter-button input[type="reset"] {
  margin-right: 10px;
  background: #37b547;
}

.newsletter-button input[type="submit"]:hover {}

/* Footer */
.footer-article {
  /* background-color: #f5f6f7; */
}

.footer {
  padding: 80px 0 60px;
  background: url(../src/assets/image/bg_footer.png) no-repeat;
  background-size: cover;
}

.footer-title {
  text-transform: uppercase;
  font-size: 15px;
  font-family: "semibold";
  color: #fff;
  margin-bottom: 25px;
}

.name-company {
  color: #1e4096;
  font-family: Baloo;
  font-size: 42px;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-shadow: 1px 0 0 #ffffff, 1px 0 0 #ffffff, 0 -1px 0 #ffffff,
    0 1px 0 #ffffff, -1px 1px 0px #ffffff, -1px 0px 0px #ffffff,
    0px 1px 0px #ffffff, 1px 1px 0px #ffffff, -1px -1px 0px #ffffff,
    1px -1px 0px #ffffff;
}

.footer-info {
  color: #fff;
}

.footer-ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-bottom: 30px;
}

.footer-ul li {
  margin-bottom: 10px;
  width: 100%;
}

.footer-ul li:last-child {
  margin-bottom: 0px;
}

.footer-ul li a {
  color: #fff;
}

.footer-ul li a:hover {
  text-decoration: none;
  color: #1e4096;
}

.footer-tags {
  background: #eee;
}

.footer-tags .wrap-content {
  padding: 30px 15px;
}

.footer-tags-lists {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.footer-tags-lists li {
  float: left;
}

.footer-tags-lists li a {
  padding-bottom: 0.375rem;
}

.footer-powered {
  margin-top: 55px;
  color: #ffffff;
  border-radius: 40px;
  background: #f4941b;
  padding: 0 40px;
  line-height: 60px;
}

.footer-powered .wrap-content {
  padding-top: 15px;
  padding-bottom: 15px;
}

.footer-statistic {
  text-align: right;
}

.footer-statistic span {
  padding-right: 10px;
}

.footer-statistic span:last-child {
  padding-right: 0px;
}

#footer-map {
  position: relative;
  height: 500px;
}

#footer-map iframe {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0px !important;
  left: 0px !important;
}

/* Like Share */
.social-plugin {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.social-plugin .at-share-btn {
  margin-bottom: 0px !important;
}

.social-plugin .zalo-share-button {
  margin-left: 3px;
}

/* Paging */
.pagination-home .pagination .page-item .page-link {
  color: #555555;
  font-size: 0.875rem;
}

.pagination-home .pagination .page-item.active .page-link {
  color: #ffffff;
  background-color: #555555;
  border-color: #555555;
}

/* Paging Ajax */
.pagination-ajax {
  text-align: center;
}

.pagination-ajax a {
  display: inline-block;
  vertical-align: top;
  margin: 0px 3px 3px 3px;
  width: 35px;
  height: 35px;
  line-height: 33px;
  color: #666 !important;
  border: 1px solid #e6e6e6;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none !important;
  background-color: #ffffff;
}

.pagination-ajax a.current,
.pagination-ajax a:hover {
  color: #ffffff !important;
  border-color: var(--background-static);
  background-color: var(--background-static);
}

.pagination-ajax a.first,
.pagination-ajax a.last,
.pagination-ajax a.prev,
.pagination-ajax a.next {
  text-indent: -9999px;
  position: relative;
  background-color: #ffffff !important;
}

.pagination-ajax a.first:before,
.pagination-ajax a.last:before,
.pagination-ajax a.prev:before,
.pagination-ajax a.next:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}

.pagination-ajax a.first:before {
  /* background-image: url(../images/page-first.png); */
}

.pagination-ajax a.last:before {
  /* background-image: url(../images/page-last.png); */
}

.pagination-ajax a.prev:before {
  /* background-image: url(../images/page-prev.png); */
}

.pagination-ajax a.next:before {
  /* background-image: url(../images/page-next.png); */
}

/* Popup */
#popup .modal-body {
  padding: 0px;
}

/* Hidden Google Captcha */
.grecaptcha-badge {
  display: none !important;
  width: 0px !important;
  height: 0px !important;
  visibility: hidden !important;
  overflow: hidden;
}

/* Hidden Check Grammar Coccoc */
coccocgrammar {
  display: none;
}

/* Scroll Top */
.scrollToTop {
  width: 41px;
  height: 41px;
  text-align: center;
  font-weight: bold;
  color: #444;
  text-decoration: none;
  position: fixed;
  bottom: 65px;
  right: 25px;
  display: none;
  z-index: 10;
  cursor: pointer;
}

/* Text Hide */
.text-split {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

/* Transition All */
.transition,
.control-owl button,
.carousel-comment-media .carousel-control a span,
.menu ul li a.has-child:after,
.menu ul li ul,
.menu ul li:hover>ul,
.scale-img img,
.scale-img:hover>img,
.btn-frame .kenit-alo-circle-fill,
.support-online .kenit-alo-circle-fill {
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

/* Scale IMG */
.scale-img {
  overflow: hidden;
  display: block;
}

.scale-img img {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.scale-img:hover>img {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

/* Blink */
.blink {
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* Button Frame */
.btn-frame {
  display: block;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  z-index: 10;
  cursor: pointer;
}

.btn-frame i {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--background-static);
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-frame i img {
  vertical-align: middle;
  width: 70%;
}

.btn-frame .animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.btn-frame .kenit-alo-circle {
  width: 60px;
  height: 60px;
  top: -5px;
  right: -5px;
  position: absolute;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid var(--background-static);
  opacity: 0.1;
  border-color: var(--background-static);
  opacity: 0.5;
}

.btn-frame .zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

.btn-frame .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.btn-frame .kenit-alo-circle-fill {
  width: 70px;
  height: 70px;
  top: -10px;
  right: -10px;
  position: absolute;
  border-radius: 100%;
  border: 2px solid transparent;
  background-color: rgba(7, 41, 103, 0.35);
  opacity: 0.4;
}

.btn-frame .pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

/* Zalo */
.btn-zalo {
  bottom: 315px;
}

.btn-zalo2 {
  bottom: 400px;
}

/* Phone */
.btn-phone {
  bottom: 230px;
}

/* Messenger */
.js-facebook-messenger-top-header {
  background: #487dfc;
  color: #fff;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px 5px 0 0;
}

.js-facebook-messenger-container.closed {
  display: none !important;
}

.js-facebook-messenger-tooltip {
  bottom: 97px;
  right: 97px;
  color: #404040;
  background: #fff;
}

.js-facebook-messenger-tooltip.closed {
  display: none !important;
}

.js-facebook-messenger-box,
.js-facebook-messenger-button {
  z-index: 999;
}

.js-facebook-messenger-tooltip {
  z-index: 999;
  display: none;
  position: fixed;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  font-size: 12px;
  line-height: 1;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0 2pt 10pt;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2pt 10pt;
  z-index: 1000000000000000019884624838656;
}

.js-facebook-messenger-close-tooltip {
  width: 10px;
  height: 10px;
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
}

.js-facebook-messenger-box {
  z-index: 999;
}

.js-facebook-messenger-box.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

.js-facebook-messenger-box.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.js-facebook-messenger-button,
.js-facebook-messenger-tooltip {
  z-index: 999;
}

.js-facebook-messenger-box {
  display: block;
  position: fixed;
  cursor: pointer;
  bottom: 150px;
  right: 17px;
  width: 56px;
  height: 56px;
  text-align: center;
  background: var(--background-static);
  border-radius: 100%;
  overflow: hidden;
  z-index: 99;
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.js-facebook-messenger-box.rotate svg#fb-msng-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.js-facebook-messenger-box svg#fb-msng-icon {
  width: 32px;
  height: 33px;
  position: absolute;
  top: 13px;
  left: 12px;
  opacity: 1;
  overflow: hidden;
  -webkit-transition: opacity 160ms ease-in-out, transform 160ms ease-in-out;
  -webkit-transition: opacity 160ms ease-in-out,
    -webkit-transform 160ms ease-in-out;
  transition: opacity 160ms ease-in-out, -webkit-transform 160ms ease-in-out;
  transition: opacity 160ms ease-in-out, transform 160ms ease-in-out;
  transition: opacity 160ms ease-in-out, transform 160ms ease-in-out,
    -webkit-transform 160ms ease-in-out;
}

.js-facebook-messenger-box.rotate svg#close-icon {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.js-facebook-messenger-box svg#close-icon {
  opacity: 0;
  width: 19px;
  height: 20px;
  position: absolute;
  top: 19px;
  left: 19px;
  -webkit-transition: opacity 160ms ease-in-out, transform 160ms ease-in-out;
  -webkit-transition: opacity 160ms ease-in-out,
    -webkit-transform 160ms ease-in-out;
  transition: opacity 160ms ease-in-out, -webkit-transform 160ms ease-in-out;
  transition: opacity 160ms ease-in-out, transform 160ms ease-in-out;
  transition: opacity 160ms ease-in-out, transform 160ms ease-in-out,
    -webkit-transform 160ms ease-in-out;
}

.js-facebook-messenger-container,
.js-facebook-messenger-container-button {
  z-index: 1000;
}

.js-facebook-messenger-container {
  width: 250px;
  position: fixed;
  opacity: 0;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  bottom: 110px;
  right: 90px;
  border-radius: 10px;
  pointer-events: none;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06),
    0 2px 32px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06), 0 2px 32px rgba(0, 0, 0, 0.16);
  -webkit-transition: transform 160ms ease-in-out, opacity 160ms ease-in-out;
  -webkit-transition: opacity 160ms ease-in-out,
    -webkit-transform 160ms ease-in-out;
  transition: opacity 160ms ease-in-out, -webkit-transform 160ms ease-in-out;
  transition: transform 160ms ease-in-out, opacity 160ms ease-in-out;
  transition: transform 160ms ease-in-out, opacity 160ms ease-in-out,
    -webkit-transform 160ms ease-in-out;
}

.js-facebook-messenger-top-head {
  width: 220px;
  color: #ffffff;
  background: #1182fc;
  display: block;
  position: relative;
  width: 220px;
  background: #1182fc;
  color: #ffffff;
  text-align: center;
  line-height: 1;
  padding: 10px;
  font-size: 14px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.js-facebook-messenger-container iframe,
.js-facebook-messenger-container-button iframe {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.js-facebook-messenger-box,
.js-facebook-messenger-button,
.js-facebook-messenger-tooltip {
  z-index: 999;
}

.js-facebook-messenger-container,
.js-facebook-messenger-container-button {
  z-index: 1000;
}

.js-facebook-messenger-top-head {
  color: #ffffff;
  background: #1182fc;
  width: 220px;
}

.js-facebook-messenger-tooltip {
  color: #404040;
  background: #fff;
}

.js-facebook-messenger-container.open {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  pointer-events: all;
}

.js-facebook-messenger-tooltip {
  bottom: 97px;
  right: 97px;
}

.js-facebook-messenger-box.open svg#fb-msng-icon {
  opacity: 0;
}

.js-facebook-messenger-box.rotate.open svg#close-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.js-facebook-messenger-box.open svg#close-icon {
  opacity: 1;
}

/* Cart Fix */
.cart-fixed {
  position: fixed;
  right: 20px;
  bottom: 390px;
  z-index: 10;
  background: #4b4f56;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff !important;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cart-fixed i {
  font-size: 20px;
}

.cart-fixed span {
  position: absolute;
  top: 0px;
  right: -5px;
  color: #ffffff;
  width: 25px;
  height: 25px;
  background: #ec2d3f;
  text-align: center;
  line-height: 25px;
  font-size: 11px;
  border-radius: 100%;
}

/* Toolbar */
@keyframes tada {
  0% {
    transform: scaleX(1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.3, 1.3, 1.3) rotate(3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.3, 1.3, 1.3) rotate(-3deg);
  }

  to {
    transform: scaleX(1);
  }
}

.toolbar {
  background: var(--background-static);
  width: 100%;
  padding: 3vw 0;
  bottom: 0px;
  position: fixed;
  z-index: 500;
  height: auto;
  left: 0px;
  max-height: 65px;
}

.toolbar ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin: 0px;
}

.toolbar ul li {
  text-align: center;
  width: 25%;
}

.toolbar ul li a {
  display: block;
  width: 100%;
  cursor: pointer;
}

.toolbar ul li a img {
  height: 20px;
  width: 20px;
  animation: tada 1.2s infinite;
}

.toolbar ul li a span {
  color: #fff;
  font-size: 9px;
}

/* Fixbar */
.fixbar {
  bottom: 0;
  display: block;
  background: #f0eff4;
  border-top: 1px solid #ddd;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  margin: 0;
  z-index: 500;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 50px;
}

.fixbar ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.fixbar ul li {
  margin: 0 auto 10px;
  text-align: center;
  width: 25%;
  float: left;
}

.fixbar .icon-cart-mobile,
.fixbar .icon-cart-new,
.fixbar .icon-home-new,
.fixbar .icon-hotdeal-new {
  width: 20px;
  height: 20px;
  display: block;
  margin: 8px auto 0;
}

.fixbar .icon-cart-mobile {
  /* background: url(../images/cart-mobile.png) no-repeat; */
}

.cart-total-head-mobile {
  font-size: 10px;
  position: absolute;
  background: red;
  color: #fff !important;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  line-height: 15px;
  top: 5px;
  margin-left: 5px;
}

.fixbar ul li a {
  font-size: 11px;
  text-decoration: none;
  color: #333;
}

.fixbar ul li a i {
  font-size: 20px;
}

/* Plugbar */
.plugbar {
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #eae6e6;
  width: 100%;
  right: 0;
  max-width: 767px;
  margin: 0 auto;
  background: #ffffff;
  padding: 9px 10px 10px 7px;
  z-index: 10;
}

.plugbar ul {
  list-style: none;
  padding: 0;
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.plugbar ul li {
  width: 20%;
  float: left;
}

.plugbar ul li a {
  display: block;
  color: #696969;
  font-size: 12px;
  text-align: center;
  position: relative;
}

.plugbar ul li a i {
  font-size: 20px;
  color: #696969;
}

.plugbar ul li a span {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -40px;
  left: calc(50% - 50px / 2);
  font-size: 14px;
  display: block;
  border: 1px solid rgba(234, 230, 230, 0.5);
  border-radius: 100%;
}

/* Combo Phone */
.support-online {
  position: fixed;
  z-index: 999;
  left: 10px;
  bottom: 0px;
}

.support-online a {
  position: relative;
  margin: 20px 10px;
  text-align: left;
  width: 40px;
  height: 40px;
}

.support-online i {
  width: 40px;
  height: 40px;
  background: #43a1f3;
  color: #ffffff;
  border-radius: 100%;
  font-size: 20px;
  text-align: center;
  line-height: 1.9;
  position: relative;
  z-index: 999;
}

.support-online a {
  display: block;
}

.support-online a span {
  border-radius: 2px;
  text-align: center;
  background: #67b634;
  padding: 9px;
  display: none;
  width: 180px;
  margin-left: 10px;
  position: absolute;
  color: #ffffff;
  z-index: 999;
  top: 0px;
  left: 40px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -webkit-animation: headAnimation 0.7s 1;
  animation: headAnimation 0.7s 1;
}

.support-online a:hover span {
  display: block;
}

.support-online a span:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #67b634 transparent transparent;
  position: absolute;
  left: -10px;
  top: 10px;
}

.support-online .kenit-alo-circle-fill {
  width: 60px;
  height: 60px;
  top: -10px;
  position: absolute;
  border-radius: 100%;
  border: 2px solid transparent;
  background-color: rgba(0, 175, 242, 0.5);
  opacity: 0.75;
  right: -10px;
}

.support-online .kenit-alo-circle {
  width: 50px;
  height: 50px;
  top: -5px;
  right: -5px;
  position: absolute;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  opacity: 0.1;
  border-color: #0089b9;
  opacity: 0.5;
}

.support-online .support-online .btn-support {
  cursor: pointer;
}

.support-online .mes i {
  background: orange;
}

.support-online .sms i {
  background: red;
}

.support-online .call-now i {
  background: green;
}

/* Phone Switch */
.widget-mobile {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 9999999;
}

#my-phone-circle {
  position: relative;
  width: 50px !important;
  height: 50px !important;
}

.wcircle-open .wcircle-icon i:before {
  content: "\f00d";
}

.wcircle-icon {
  background: #1282fc;
  border-radius: 50%;
  position: relative !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wcircle-icon:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  background: rgba(18, 130, 252, 0.5);
  border: 1px solid #ffffff;
  border-radius: 50%;
  left: -5px;
  top: -5px;
  -webkit-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
}

.wcircle-icon:after {
  position: absolute;
  content: "";
  width: 80px;
  height: 80px;
  background: rgba(18, 130, 252, 0.5);
  border-radius: 50%;
  left: -15px;
  top: -15px;
  -webkit-animation: zoomIn 2s infinite ease-in-out;
  animation: zoomIn 2s infinite ease-in-out;
}

.wcircle-menu {
  position: absolute !important;
  left: 0;
  top: 0;
  display: none;
}

.wcircle-menu-item {
  width: 50px;
  height: 50px;
  background: #1282fc;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wcircle-menu-item img {
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 50%;
}

.wcircle-menu-item i {
  font-size: 25px;
  color: #ffffff;
  position: relative;
  z-index: 9999;
}

.wcircle-icon i {
  font-size: 25px;
  color: #ffffff;
  position: relative;
  z-index: 9999;
}

.shake-anim {
  -webkit-animation: shake-anim 1s infinite ease-in-out;
  animation: shake-anim 1s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.wrap-newsnb {}

.wrap-newsnb .wrap-content {
  position: relative;
}

.item-newsnb {}

.pic-newsnb {}

.info-newsnb {}

a.name-newsnb {
  color: #000;
  font-size: 14px;
  margin: 0 0 10px 0;
  line-height: normal;
}

a.name-newsnb.text-split {
  -webkit-line-clamp: 2;
}

a.name-newsnb:hover {
  color: var(--color-dark-red);
}

.desc-newsnb {
  color: #333;
}

.video-main {
  position: relative;
  height: 400px;
}

.video-main iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.listvideos {
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  border: 1px solid #ccc;
  color: var(--color-black);
  margin-top: 10px;
  cursor: pointer;
}

.item-video1 img {
  max-height: 292px;
}

.item-video2 img {
  height: 102px;
}

.pic-video {
  position: relative;
  cursor: pointer;
}

.pic-video:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 35px;
  top: calc(50% - 50px / 2);
  left: calc(50% - 35px / 2);
  z-index: 1;
  /* background: url(../images/play.png) no-repeat center center; */
  cursor: pointer;
  background-size: cover;
}

.pic-video img {
  width: 100%;
}

.pic-video-2 {
  position: relative;
  cursor: pointer;
}

.pic-video-2:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 35px;
  top: calc(50% - 50px / 2);
  left: calc(50% - 35px / 2);
  z-index: 1;
  /* background: url(../images/play.png) no-repeat center center; */
  cursor: pointer;
  background-size: cover;
}

.pic-video-2 img {
  width: 100%;
}

.name-video {
  font-size: 16px;
  color: var(--color-black);
  margin-bottom: 0px;
}

.owl-video {
  margin-top: 10px;
}

.newshome-intro .name-newshome {
  margin: 10px 0;
}

.newshome-intro .view-newshome {
  margin-top: 10px;
}

.newshome-scroll {
  width: 50%;
  overflow: hidden;
  max-height: 406px;
}

.news-slick {
  display: flex;
  margin-bottom: 20px;
}

.news-slick h3 {
  margin: 0;
}

.news-slick h3 a.name-newshome {
  margin-bottom: 10px;
}

.news-slick .img {
  width: 150px;
}

.news-slick .info {
  width: calc(100% - 150px);
  padding-left: 15px;
}

.flex_ttnb {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.newshome-intro {
  width: calc(50% - 20px);
}

.pic-newshome-normal {
  float: left;
  width: 34%;
  margin-right: 3%;
  margin-bottom: 0px;
}

.pic-newshome-normal img {
  width: 100%;
}

.info-newshome-normal {
  float: left;
  width: calc(100% - 37%);
}

.name-newshome {
  color: var(--color-black);
  font-size: 14px;
  line-height: normal;
}

.name-newshome.text-split {
  -webkit-line-clamp: 2;
}

.name-newshome.text-split a {
  color: #000;
}

.time-newshome {
  color: var(--color-gray);
  margin-bottom: 10px;
}

.desc-newshome {
  color: var(--color-black);
  margin-bottom: 0px;
  text-align: justify;
}

.desc-home-cl.text-split {
  -webkit-line-clamp: 2;
}

.view-newshome {
  width: 140px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  color: #000;
}

.view-newshome:hover {
  color: #000;
}

.box-product {
  position: relative;
  margin-bottom: 20px;
}

.pic-product {
  background: #ffffff;
  position: relative;
  margin-bottom: 1rem;
}

.pic-product img {
  width: 100%;
}

.product-tool {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  z-index: 1;
}

.product-tool a {
  cursor: pointer;
  width: 50%;
  padding: 10px;
  text-align: center;
  color: #ffffff;
  background-color: rgb(0 0 0 / 0.6);
}

.product-tool a:hover {
  background-color: rgb(0 0 0 / 0.8);
}

.product-tool a svg {
  margin-right: 5px;
}

.product-tool a span {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 300;
}

.box-product:hover .product-tool {
  opacity: 1;
  visibility: visible;
}

.name-product {
  font-size: 14px;
  color: var(--color-black);
  margin-bottom: 10px;
}

.name-product:hover {}

.name-product.text-split {
  -webkit-line-clamp: 2;
}

.price-product {
  margin-bottom: 10px;
}

.price-product span {}

.price-new {
  font-size: 15px;
  color: var(--color-red);
  font-weight: 700;
}

.price-old {
  padding-left: 10px;
  color: var(--color-gray);
  text-decoration: line-through;
}

.price-per {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ffffff;
  background: var(--color-red);
  font-size: 11px;
  border-radius: 2px;
  width: 45px;
  height: 25px;
  text-align: center;
  line-height: 25px;
}

.cart-product {
  margin-bottom: 0px;
}

.cart-product span {
  cursor: pointer;
  width: calc(50% - 3px);
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  padding: 7px 5px;
  border-radius: 3px;
}

.cart-add {
  background-color: var(--color-red);
}

.cart-add:hover {
  background-color: var(--color-dark-red);
}

.cart-buy {
  background-color: var(--color-green);
}

.cart-buy:hover {
  background-color: var(--color-dark-green);
}

.dm-noibat {
  max-width: 1000px;
  position: relative;
  margin: auto;
  margin-bottom: 40px;
}

.dm-noibat a {
  cursor: pointer;
  color: #333;
  transition: 0.4s;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  position: relative;
  display: block;
  background: #f7f7f7;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  height: 100%;
  padding: 10px;
}

.dm-noibat a:hover,
.dm-noibat a.active {
  background: var(--background-static);
  color: #fff;
}

.cats-bar {
  width: 50px;
  padding-top: 3px;
  position: relative;
}

.cats-bar-icon {
  width: 30px;
  cursor: pointer;
}

.cats-bar-icon span {
  display: block;
  width: 100%;
  height: 2px;
  background: #505050;
  transition: all 0.3s;
  position: relative;
}

.cats-bar-icon span+span {
  margin-top: 5px;
}

.cats-bar-icon.active span:nth-child(1) {
  animation: ease 0.7s top forwards;
}

.cats-bar-icon.not-active span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
}

.cats-bar-icon.active span:nth-child(2) {
  animation: ease 0.7s scaled forwards;
}

.cats-bar-icon.not-active span:nth-child(2) {
  animation: ease 0.7s scaled-2 forwards;
}

.cats-bar-icon.active span:nth-child(3) {
  animation: ease 0.7s bottom forwards;
}

.cats-bar-icon.not-active span:nth-child(3) {
  animation: ease 0.7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }

  50% {
    top: 6px;
    transform: rotate(0);
  }

  100% {
    top: 6px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 6px;
    transform: rotate(45deg);
  }

  50% {
    top: 6px;
    transform: rotate(0deg);
  }

  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }

  50% {
    bottom: 8px;
    transform: rotate(0);
  }

  100% {
    bottom: 8px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 8px;
    transform: rotate(135deg);
  }

  50% {
    bottom: 8px;
    transform: rotate(0);
  }

  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.cats-owl.list-hot {
  width: calc(100% - 50px);
}

.footer-news:nth-child(4) {
  width: 300px;
}

.footer-news:nth-child(3) {
  width: 25%;
  text-align: center;
}

.footer-news:nth-child(2) {
  width: 25%;
  padding: 0 20px;
}

.footer-news:nth-child(1) {
  width: 50%;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.choose_list {}

.choose_list span {
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 0 10px;
}

.choose_list span:last-child:after {
  display: none;
}

.choose_list span:after {
  content: "";
  width: 1px;
  height: 10px;
  background: #ddd;
  position: absolute;
  right: 0;
  top: 6px;
}

.choose_list span.choosed {
  color: #f00;
}

.btn_sp {}

.btn_sp a {
  color: #000;
  text-transform: uppercase;
  background: #e6e6e6;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.btn_sp a:hover {
  color: #f00;
}

.d-title-choose-list {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 5px;
}

.d-title-choose-cat {}

/* dat lich */
.div-datlich {
  width: 46%;
}

.form-booking {}

.newsletter-booking {
  margin-bottom: 5px;
}

.newsletter-booking input {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--background-static);
  background: none;
  padding: 0;
}

.newsletter-booking input::placeholder {}

.newsletter-booking textarea {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--background-static);
  background: none;
  margin-bottom: 10px;
  padding: 0;
}

.newsletter-booking textarea::placeholder {}

.newsletter-checkbox {}

.newsletter-checkbox input {
  outline: none;
  width: 15px;
  height: 15px;
}

.d-dongy {}

.label-checkbox {
  color: #7e7e7e;
  position: relative;
  top: 1px;
  margin-left: 10px;
}

.booking-button {}

.booking-button input {
  width: 86px;
  height: 37px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
}

.khung-datlich {
  background: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border: 1px solid var(--background-static);
  border-radius: 20px;
}

.desc-dongy {
  color: #7e7e7e;
  font-size: 13px;
  font-style: italic;
  margin-top: 5px;
}

.btn-datlich {
  position: fixed;
  bottom: 455px;
  right: 10px;
  padding: 10px;
  border-radius: 5px;
  z-index: 99;
  background: #333;
  color: #fff;
  font-size: 14px;
  text-align: center;
  text-decoration: none !important;
}

.btn-datlich i {
  font-size: 20px;
}

.btn-datlich:hover {
  color: #ffff00;
  transition: 0.2s;
}

/* animation */
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes shake-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    transform: rotate(0) scale(1) skew(1deg);
  }

  10%,
  30% {
    -moz-transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20%,
  40% {
    -moz-transform: rotate(25deg) scale(1) skew(1deg);
  }

  100%,
  50% {
    -moz-transform: rotate(0) scale(1) skew(1deg);
  }
}

@-webkit-keyframes shake-anim {

  0%,
  100%,
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }

  10%,
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20%,
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes scaleLarge {
  0% {
    opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* effect */
.spacing-letter {
  transition: 0.3s;
}

.spacing-letter:hover {
  letter-spacing: 1px;
  transition: 0.3s;
}

/* my css */

.gioithieu-pic--item {
  border: 2px dashed #f4941b;
}

.gioithieu-pic--item img {
  /* mask-image: url("../images/mask_gioithieu_pic.png");
  -webkit-mask-image: url("../images/mask_gioithieu_pic.png");
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat; */
}

.gioithieu-pic--owl {
  /* padding: 20px;
  background: #37b547;
  mask-image: url("../images/mask_gioithieu.png");
  -webkit-mask-image: url("../images/mask_gioithieu.png");
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat; */
}

.gioithieu-inside {
  background: url(../src/assets/image/bg_gioithieu_inside.png) no-repeat;
  background-size: 100% 100%;
  padding: 80px 0;
}

.gioithieu-info {
  max-width: 470px;
}

.gioithieu-info span {
  font-size: 16px;
  line-height: 26px;
  -webkit-line-clamp: 4;
}

.combo-btn {
  margin-top: 40px;
}

.combo-btn a {
  color: #fff;
  font-family: Baloo;
  font-size: 14px;
  font-style: normal;
  /* 185.714% */
  background: var(--background-static);
  text-decoration: none;
  padding: 0 30px;
  line-height: 42px;
  border-radius: 10px;
}

.combo-btn a:first-child {
  background: #f4941b;
  margin-right: 10px;
}

.combo-btn a i img {
  margin-top: 13px;
  margin-right: 6px;
}

.combo-btn a:first-child i img {
  margin-top: 16px;
  margin-right: 0;
  margin-left: 6px;
}

.gioithieu-info h3 {
  color: #37b547;
  font-family: Baloo;
  font-size: 42px;
  text-transform: uppercase;
}

.gioithieu-index {
  padding-top: 20px;
}

.gioithieu-pic {
  max-width: 545px;
  margin-left: auto;
}

/* loi the */
.loithe-index {
  padding: 20px 0 40px;
  display: contents;
}

.loithe {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
  align-items: flex-start;
}

.loithe-pic {
  width: 100%;
  max-width: 80px;
  text-align: center;
  background: #b4e8bb;
  padding: 15px 0px;
  border-radius: 20px;
  margin-right: 7px;
}

.loithe-pic:hover {
  background: #fbdcb6;
}

.loithe-info {
  width: 70%;
  max-width: calc(100% - 95px);
}

.loithe-info h3 {
  font-size: 16px;
  font-family: semibold;
  margin-bottom: 7px;
  -webkit-line-clamp: 2;
  height: 38px;
  line-height: 19px;
}

/* chuong trinh hoc */
.chuongtrinhhoc-index {
  background: #ceedd2;
  display: block;
  position: relative;
  /* background: 
  url(../src/assets/image/des_menu2.png) repeat-x bottom left, 
  url(../src/assets/image/des_menu2.png) repeat-x top left, 
  #ceedd2; */
  /* background: url(../src/assets/image/des_menu2.png) repeat-x top left, #ceedd2; */
  /* background: url(../src/assets/image/des_menu2.png) repeat-x  left, #ceedd2; */

}




.chuongtrinhhoc-index:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25px;
  background: url(../src/assets/image/des_menu2.png) repeat-x top left;
  transform: rotate(180deg);
}

.chuongtrinhhoc-index:after {
  content: "";
  position: absolute;
  top: 0;
  /* Đặt vị trí ở phía trên */
  left: 0;
  width: 100%;
  height: 25px;
  background: url(../src/assets/image/des_menu2.png) repeat-x top left;
  /* Ảnh nền ban đầu */
}

.chuongtrinhhoc-info h3 {
  font-family: Baloo;
  font-size: 26px;
  margin-bottom: 35px;
}

.chuongtrinhhoc-info div {
  height: 290px;
  overflow-y: auto;
  max-width: 65%;

}

.chuongtrinhhoc-info h3 a {
  color: #1e4096;
  text-decoration: none;
  text-transform: capitalize;
  -webkit-line-clamp: 1;
  text-align: center;
}

.chuongtrinhhoc {
  position: relative;
  background: url(../src/assets/image/mask_chuongtrinh_item.png) no-repeat;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 45px 0 15px 20px;
  margin-bottom: 20px;
}

/* .chuongtrinhhoc .slick-current {
  margin: 0 15px;
  background: pink;
}
.slick-slide {
  transition: margin 0.3s ease;
}

.chuongtrinhhoc.slick-active {
  margin: 0 15px;
} */

/* {
  background:pink !important;

} */
.testClass .slick-slide .slick-active .slick-current {
  background: yellow !important;
}

.btn-xemthem-cth {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.btn-xemthem-cth a {
  background: #f4941b;
  display: inline-block;
  padding: 15px 15px 15px 15px;
  border-radius: 10px;
}

.chuongtrinhhoc:hover {
  background: url(../src/assets/image/mask_chuongtrinh_item_hover.png) no-repeat;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 45px 0 15px 20px;
  margin-bottom: 20px;
}

.chuongtrinhhoc:hover h3 a {
  color: #f4941b;
}

.slick-slide .slick-active {
  background: pink !important;
}

.chuongtrinhhoc:hover .chuongtrinhhoc-info div {
  color: #fff;
}

.chuongtrinhhoc-pic {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.ykien-contain-top--outside {
  max-width: 1050px;
  margin: 0 auto;
}

.ykien-contain-bottom {
  position: relative;

}

.ykien-contain-bottom:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 146px;
  height: 210px;
  background: url(../src/assets/image/des_1_ykien.png);
}

.ykien-contain-bottom:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 157px;
  height: 214px;
  background: url(../src/assets/image/des_2_ykien.png);
}

.ykien-contain-top {
  margin: 0 -70px;
}

.ykien-pic {
  margin: 20px 70px;
  padding: 10px;
  border: 2px dashed #37b547;
  transform: rotate(10deg);
  border-radius: 8px;
  background: #fff;
  width: fit-content;
}

.ykien-pic--outside.slick-center .tranfrom-scale .ykien-pic {
  transform: revert;
  border-radius: 50%;
  border-color: #f4941b;
}

.ykien-pic--outside.slick-center .tranfrom-scale {
  transform: scale(1);
  transition: 0.3s;
}

.ykien-pic--outside.slick-center .tranfrom-scale .ykien-pic img {
  border-radius: 50%;
}

.tranfrom-scale {
  transform: scale(0.75);
  transition: 0.3s;
}

.ykien-info {
  max-width: 620px;
  margin: 0 auto;
  text-align: center;
  padding: 25px 0 50px;
  position: relative;
}

.ykien-info:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 56px;
  height: 44px;
  background: url(../src/assets/image/des_ykien_content.png);
}

.ykien-info:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 44px;
  background: url(../src/assets/image/des_ykien_content.png);
  transform: rotateY(180deg);
}

.ykien-info h3 {
  color: #37b547;
  text-align: center;
  font-family: Baloo;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 20px;
}

.ykien-info span {
  line-height: 26px;
  font-size: 16px;
  -webkit-line-clamp: 5;
}

.ykien-contain {
  position: relative;
}

.ykien-contain:after {
  content: "";
  position: absolute;
  top: 23%;
  left: 0;
  width: 100%;
  height: 172px;
  background: url(../src/assets/image/des_ykien.png) no-repeat;
  background-size: 100%;
  pointer-events: none;
  z-index: -1;
}

/* album */
.thuvien-item {
  margin-bottom: 30px;
}

.thuvien-col {}

.thuvien-item a {
  border-radius: 70px;
  margin: 5px;
}

.btn-xemthem {
  margin-top: 15px;
  text-align: center;
}

.btn-xemthem a {
  font-family: baloo;
  line-height: 42px;
  background: #f4941b;
  display: inline-block;
  color: #fff;
  border-radius: 10px;
  padding: 0 30px;
}

.btn-xemthem a i img {
  margin-top: 16px;
  margin-left: 10px;
}

.thuvien-index {
  padding: 80px 0;
  background: #ceedd2;
  display: block;
  position: relative;
}

.thuvien-index:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
  /* background: url(../images/des_menu.png) repeat-x bottom left; */
  background: url(../src/assets/image/des_menu2.png) repeat-x top left;
}

.thuvien-index:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 25px;
  background: url(../src/assets/image/des_menu2.png) repeat-x top left;
  transform: rotate(180deg);
}

/* form */
.form-index {
  padding: 60px 0 25px;
  display: contents;
}

.form-index .title-main {
  margin-bottom: 35px;
}

.form-index .title-main p {
  margin-bottom: 7px;
}

.form-contain {
  background: url(../src/assets/image/bg_form.png) no-repeat;
  background-size: cover;
  width: 1108px;
  height: 712px;
  margin: 0 auto;
  padding-top: 140px;
}

.form-floating.form-floating-cus.cus-newsletter>input {
  border-radius: 60px;
  border: 1px solid #ffd8a6;
  background: #fff;
  position: relative;
}

.form-newsletter .col-12:nth-child(1) .form-floating.form-floating-cus.cus-newsletter:after {
  content: "";
  position: absolute;
  top: 35%;
  right: 25px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  /* background: url(../src/assets/image/user.png); */
}

.form-newsletter .col-12:nth-child(2) .form-floating.form-floating-cus.cus-newsletter:after {
  content: "";
  position: absolute;
  top: 35%;
  right: 25px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  /* background: url(../images/smartphone.png); */
}

.form-newsletter .col-12:nth-child(3) .form-floating.form-floating-cus.cus-newsletter:after {
  content: "";
  position: absolute;
  top: 35%;
  right: 25px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  /* background: url(../images/edit.png); */
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

/* video */
.videoHot-index {
  padding: 30px 0;
}

.videoHot-index .title-main {
  margin-bottom: 40px;
}

.item-video {
  border-radius: 70px;
  position: relative;
}

.item-video:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 54px;
  height: 54px;
  /* background: url(../images/play-ytb.png); */
  pointer-events: none;
}

/* co so */
.hethong-index {
  padding: 80px 0 110px;
  background: url(../src/assets/image/bg_coso.png) no-repeat;
  background-size: 100% 100%;
  display: block;
}

.hethong-index .title-main {
  margin-bottom: 40px;
}

.hethong-pic a {
  border-radius: 70px;
}

.hethong-pic a img {
  height: 330px;
}

.hethong-info {
  background: #fff;
  border-radius: 70px;
  padding: 220px 20px 40px 20px;
  margin: 0 10px
}

.hethong-info h3 {
  text-align: center;
  font-family: Baloo;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.hethong-info>a {
  width: 200px;
  line-height: 42px;
  background: #f4941b;
  text-align: center;
  margin: 30px auto 0;
  border-radius: 10px;
  color: #fff;
}

.hethong-info>a i {
  margin-right: 7px;
}

.hethong-info span {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}

.hethong-info h3 a {
  color: #37b547;
}

.hethong-info h3 a:hover {
  color: #f4941b;
}

.hethong-item {
  position: relative;
  padding-top: 160px;
}

.hethong-pic {
  position: absolute;
  top: 0;
  left: 50%;
  padding: 0 10px;
  transform: translateX(-50%);
  width: calc(100% - 40px);

}

/* newshot */
.news-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 8px;
  border: 1px solid #b4e8bb;
  border-radius: 40px;
}

.news-item:hover {
  background: #b4e8bb;
}

.news-pic {
  max-width: 44.7%;
  margin-right: 15px;
}

.news-pic a {
  border-radius: 40px;
}

.news-info {
  max-width: calc(55.3% - 25px);
}

.news-info h3 a:hover {
  color: #f4941b;
}

.news-info span {
  line-height: 24px;
  -webkit-line-clamp: 2;
}

.news-info h3 {
  font-size: 16px;
  font-family: medium;
  margin-bottom: 15px;
  line-height: 24px;
}

.news-info h3 a {
  color: #000;
}

.newsHot-time {
  margin-bottom: 10px;
  color: #aeaeae;
}

.newsHot-time i {
  color: #f4941b;
  margin-right: 3px;
}

.xemthem-bg-static {
  margin-top: 10px;
}

.xemthem-bg-static a {
  background: #37b547;
}

.newsHot-index {
  padding: 80px 0 50px;
  display: contents;
}

.newsHot-index .title-main {
  margin-bottom: 20px;
}

/* train */
@keyframes moveBackAndForth {
  0% {
    margin-left: 0;
  }

  50% {
    margin-left: 50%;
  }

  100% {
    margin-left: 100%;
  }
}

/* Áp dụng animation vào một phần tử */
.train-index span {
  animation-name: moveBackAndForth;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.train-index {
  margin-bottom: 50px;
}

/* iframe fanpage */
div#fanpage-facebook .fb_iframe_widget {
  border-radius: 50%;
  overflow: hidden;
  padding: 3px;
  border: 1px dashed #fff;
  display: inline-block;
  width: 100%;
  max-width: 260px;
}

div#fanpage-facebook .fb_iframe_widget iframe {
  border-radius: 50%;
}

.menu.menu-inside:after {
  display: none;
}

/* chuong trinh hoc inside */
.wrap-cth {
  background: #ceedd2;
}

.col-cth {
  padding: 0 10px;
  margin-bottom: 20px;
}

.row-cth {
  margin: 0 -10px;
}

/* tieu chi slide */
.tieuchi-item h3 {
  font-size: 18px;
  font-family: "Baloo";
  margin-bottom: 0;
  line-height: 17px;
  height: 38px;
}

.tieuchi-item {
  background: url(../src/assets/image/icon_tick.png) no-repeat;
  padding-left: 25px;
  margin-bottom: 15px;
}

.tieuchi-item:last-child {
  margin-bottom: 0;
}

.tieuchi-item h3 a {
  color: #000;
  -webkit-line-clamp: 2;
}

.tieuchi-item h3 a:hover {
  color: #37b547;
}

/* .tieuchi-item h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

.tieuchi-item h3:hover {
  color: #37b547;
} */

.box-tieuchi-outside {
  position: absolute;
  z-index: 10;
  bottom: 100px !important;
  left: calc((100% - 1200px) / 2);
  background-color: rgba(255, 255, 255, 0.6);
  padding: 60px 40px 30px 40px;
  border-radius: 20px;
  width: 450px;
  mask-image: url("../src/assets/image/mask_gioithieu_pic.png");
  -webkit-mask-image: url("../src/assets/image/mask_gioithieu_pic.png");
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  top: calc(50% -50px / 2);
  display: contents;
  margin-top: -25%;
}

/* chi nhanh */
.iframe_chinhanh {
  height: 500px;
}

.iframe_chinhanh iframe {
  width: 100%;
  height: 100%;
}

.block-chi-nhanh {
  position: relative;
}

.block-toa-do {
  position: absolute;
  top: 10px;
  right: calc((100% - 1200px) / 2);
  display: flex;
  margin: 0 -10px;
}

.item-toa-do {
  margin: 0 10px;
  background: var(--background-static);
  border-radius: 5px;
  overflow: hidden;
}

.item-toa-do.active h2,
.item-toa-do:hover h2 {
  background: #f4941b;
}

.ten-chi-nhanh {
  font-size: 16px;
  min-width: 150px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0;
  color: #fff;
  cursor: pointer;
}

/* end my css */

/* responsive */
@media (max-width: 1024px) {

  .block-toa-do {
    right: 15px;
  }

  div[class ^="des-"] {
    display: none;
  }

  .box-tieuchi-outside {
    display: none !important;
  }

  .hethong-info {
    padding-top: 160px;
  }

  .form-contain {
    width: calc(100% - 50px);
    background-size: 100% 100%;
  }

  .contact-input {
    margin-bottom: 15px;
  }

  .flex-cus,
  .row-video,
  .row-20 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .mg-cus,
  .mg-video,
  .col-20 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .content-text img {
    height: auto !important;
  }

  .padding-top-bottom {
    padding: 2rem 0;
  }

  .pad-top {
    padding-top: 2rem;
  }

  .pad-bottom {
    padding-bottom: 2rem;
  }

  .mg-top {
    margin-top: 2rem;
  }

  .mg-bottom {
    margin-bottom: 2rem;
  }

  .title-detail-main {
    font-size: 20px;
  }

  .cart-fixed {
    bottom: 220px;
  }

  .btn-frame {
    display: none;
  }

  .cart-pro-detail {
    width: 100%;
    margin-top: 10px;
  }

  .pad-bottom {
    padding-bottom: 2rem !important;
  }

  .pad-top {
    padding-top: 2rem !important;
  }

  .padding-top-bottom {
    padding: 2rem 0 !important;
  }

  .menu {
    display: none;
  }

  .menu-res {
    display: block;
  }

  .product-tool {
    opacity: 1;
    visibility: visible;
    position: initial;
  }

  .product-tool a span {
    display: none;
  }
}

@media (max-width: 992px) {
  .box-tieuchi-outside {
    top: 50%;
    transform: translateY(-50%);
    bottom: unset;
  }

  .gioithieu-pic--owl {
    -webkit-mask-image: unset;
    background: transparent;
  }

  .gioithieu-pic {
    margin: 0 auto 15px;
  }

  .chuongtrinhhoc,
  .chuongtrinhhoc:hover {
    background-size: 100% 100%;
  }

  .chuongtrinhhoc-pic {
    right: 10px;
  }

  .gioithieu-info {
    max-width: 100%;
  }

  .othernews {
    position: initial;
  }

  .footer-title:after {
    width: 100%;
  }

  #fanpage-facebook {
    /* width: 300px; */
    /* overflow: hidden; */
    /* margin: auto; */
  }

  .footer-news:nth-child(4) {
    width: 100%;
  }

  .footer-news:nth-child(3) {
    width: 50%;
    margin-bottom: 20px;
  }

  .footer-news:nth-child(2) {
    width: 50%;
    margin: 20px 0;
    padding: 0;
  }

  .footer-news:nth-child(1) {
    width: 100%;
  }

  .time-news {
    display: none;
  }

  .copyright,
  .statistic {
    width: 100%;
    text-align: center;
  }

  .footer-ul li {
    /* width: calc(100% / 2 - 5px); */
  }

  .footer-ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .form-contain {
    background: none;
    padding: 30px 0;
    height: auto;
  }

  .form-index {
    padding: 0;
    background: #ceedd2;
  }

  .item-video1 img {
    height: 250px;
  }

  .right-intro {
    width: 100%;
  }

  .left-intro {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0;
  }

  .left-pro-detail,
  .right-pro-detail {
    width: 100%;
    margin: 0px;
  }

  .left-pro-detail,
  .top-contact .article-contact {
    margin-bottom: 30px;
  }

  .pic-news {
    width: 40%;
  }

  .info-news {
    width: 60%;
    padding-left: 10px;
  }

  .name-news {
    font-size: 15px;
  }

  .desc-news.text-split {
    -webkit-line-clamp: 2;
  }

  .gioithieu-inside {
    padding: 40px 0;
  }

  .title-main {
    margin-bottom: 30px;
  }

  .ykien-index {
    padding: 30px 0;
    display: contents;
  }

  .thuvien-index {
    padding: 40px 0;
  }

  .hethong-index {
    padding: 40px 0;
    background: #ceedd2;
  }

  .hethong-info {
    padding-top: 80px;
  }

  .newsHot-index {
    padding: 30px 0;
  }

  .footer {
    padding: 30px 0;
    background: #37b547;
  }

  .footer-powered {
    line-height: 30px;
  }

  .footer-powered {
    margin-top: 25px;
  }

  .footer-powered,
  .footer-statistic {
    text-align: center;
  }

  .chuongtrinhhoc-index:after,
  .chuongtrinhhoc-index:before {
    display: none;
  }

  .thuvien-index:after {
    display: none;
  }

  .thuvien-index:before {
    display: none;
  }

  .ykien-pic {
    margin: 20px 55px;
  }

  .ykien-contain-bottom:after,
  .ykien-contain-bottom:before {
    display: none;
  }
}

@media (max-width: 576px) {
  .footer-statistic {
    text-align: center;
  }

  .toast-cus {
    left: 5px;
    width: calc(100% - 10px);
  }

  .box-readmore {
    width: calc(100% - 5px);
  }

  .newshome-scroll {
    width: 100%;
  }

  .newshome-intro {
    width: 100%;
    margin-bottom: 20px;
  }

  .top-contact .form-contact .button-contact .file-contact span b {
    max-width: 105px;
  }

  .cart-pro-detail {
    flex-wrap: wrap;
  }

  .cart-pro-detail a {
    width: 100%;
    text-align: center;
    margin: 0px !important;
  }

  .cart-pro-detail a:first-child {
    margin-bottom: 5px !important;
  }

  .gioithieu-index {
    padding-top: 0;
  }

  .gioithieu-inside {
    padding: 15px 0;
  }

  .gioithieu-info h3 {
    font-size: 27px;
  }

  .gioithieu-info span {
    font-size: 14px;
    line-height: 21px;
  }

  .combo-btn {
    margin-top: 10px;
  }

  .title-main span {
    font-size: 24px;
    line-height: 30px;
  }

  .loithe-index {
    padding: 20px 0 0;

  }

  .ykien-pic--outside.slick-center .tranfrom-scale .ykien-pic {
    display: inline-block;
    margin: 0;
  }

  .ykien-pic--outside.slick-center {
    text-align: center;
  }

  .ykien-index .title-main {
    margin-bottom: 15px;
  }

  .form-contain {
    width: calc(100% - 30px);
  }

  .form-index .title-main {
    margin-bottom: 15px;
  }

  .videoHot-index .title-main {
    margin-bottom: 15px;
  }

  .title-main p {
    line-height: 20px;
  }

  .hethong-index .title-main {
    margin-bottom: 15px;
  }

  .hethong-pic {
    position: relative;
    left: unset;
    transform: none;
    width: 100%;
  }

  .hethong-info {
    padding-top: 20px;
    border-radius: 0 0 70px 70px;
  }

  .hethong-pic a {
    border-radius: 70px 70px 0 0;
  }

  .hethong-item {
    padding-top: 15px;
  }

  .item-video {
    text-align: center;
  }

  .news-info {
    max-width: calc(55.3% - 5px);
    padding-right: 5px;
  }

  .news-info h3 {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 7px;
  }

  .newsHot-time {
    margin-bottom: 0px;
    font-size: 12px;
  }

  .news-info span {
    font-size: 13px;
  }

  .btn-xemthem a {
    line-height: 30px;
  }

  .btn-xemthem a i img {
    margin-top: 10px;
  }

  .news-item {
    padding: 4px;
    margin-bottom: 10px;
  }

  .btn-xemthem {
    margin-top: 0;
  }

  .train-index {
    margin-bottom: 15px;
  }

  .footer-logo img {
    max-width: 170px;
  }

  .name-company {
    font-size: 30px;
    margin-bottom: 7px;
  }

  .footer-news:nth-child(2) {
    width: 100%;
  }

  .footer-news:nth-child(3) {
    width: 100%;
  }

  .footer-powered {
    padding: 0 10px;
    font-size: 12px;
  }

  .news-info h3 a {
    -webkit-line-clamp: 2;
  }

  .ykien-index {
    padding: 15px 0;
    overflow: hidden;
  }

  .title-main p {
    font-size: 30px;
  }

  p.slogan-from {
    font-size: 14px;
  }

  .ykien-info {
    padding: 10px 0 15px;
  }

  .newsletter-button input[type="submit"],
  .newsletter-button input[type="reset"] {
    width: 130px !important;
    font-size: 15px;
    height: 35px;
  }
}

.des-loithe--after {
  position: absolute;
  top: -70px;
  right: 0;
}

.des-form--after {
  position: absolute;
  bottom: 0;
  left: calc((100% - 1200px) / 2);
}

.des-form--before {
  position: absolute;

  top: 0;
  right: calc((100% - 1200px) / 2);
}

.des-ykien--after {
  position: absolute;
  top: 0;
  left: 0;
}

.des-ykien--before {
  position: absolute;
  top: 0;
  right: 0;
}

.des-ykien--before img {
  max-height: 130px;
}

.des-album--after {
  position: absolute;
  top: -15px;
  left: 0;
}

.des-album--after img {
  max-height: 130px;
}

.des-album--before {
  position: absolute;
  top: -15px;
  right: 0;
}

div[class ^="des-"] img {
  max-height: 130px !important;
}

.mm-menu_theme-white {
  --mm-color-border: rgba(0, 0, 0, 0.1);
  --mm-color-button: rgba(0, 0, 0, 0.3);
  --mm-color-text: rgba(0, 0, 0, 0.7);
  --mm-color-text-dimmed: rgba(0, 0, 0, 0.3);
  --mm-color-background: #fff;
  --mm-color-background-highlight: rgba(0, 0, 0, 0.06);
  --mm-color-background-emphasis: rgba(0, 0, 0, 0.03);
  --mm-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
}

.mm-menu_theme-dark {
  --mm-color-border: rgba(0, 0, 0, 0.3);
  --mm-color-button: rgba(255, 255, 255, 0.4);
  --mm-color-text: rgba(255, 255, 255, 0.85);
  --mm-color-text-dimmed: rgba(255, 255, 255, 0.4);
  --mm-color-background: #333;
  --mm-color-background-highlight: rgba(255, 255, 255, 0.08);
  --mm-color-background-emphasis: rgba(0, 0, 0, 0.1);
  --mm-shadow: 0 0 20px rgba(0, 0, 0, 0.5)
}

.mm-menu_theme-black {
  --mm-color-border: rgba(255, 255, 255, 0.25);
  --mm-color-button: rgba(255, 255, 255, 0.4);
  --mm-color-text: rgba(255, 255, 255, 0.75);
  --mm-color-text-dimmed: rgba(255, 255, 255, 0.4);
  --mm-color-background: #000;
  --mm-color-background-highlight: rgba(255, 255, 255, 0.2);
  --mm-color-background-emphasis: rgba(255, 255, 255, 0.15);
  --mm-shadow: none
}

:root {
  --mm-line-height: 20px;
  --mm-listitem-size: 44px;
  --mm-navbar-size: 44px;
  --mm-offset-top: 0;
  --mm-offset-right: 0;
  --mm-offset-bottom: 0;
  --mm-offset-left: 0;
  --mm-color-border: rgba(0, 0, 0, 0.1);
  --mm-color-button: rgba(0, 0, 0, 0.3);
  --mm-color-text: rgba(0, 0, 0, 0.75);
  --mm-color-text-dimmed: rgba(0, 0, 0, 0.3);
  --mm-color-background: #f3f3f3;
  --mm-color-background-highlight: rgba(0, 0, 0, 0.05);
  --mm-color-background-emphasis: rgba(255, 255, 255, 0.4);
  --mm-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
}

.mm-hidden {
  display: none !important
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative
}

.mm-menu {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, .1);
  color: rgba(0, 0, 0, .75);
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: var(--mm-offset-top);
  right: var(--mm-offset-right);
  bottom: var(--mm-offset-bottom);
  left: var(--mm-offset-left);
  z-index: 0;
  background: var(--mm-color-background);
  border-color: var(--mm-color-border);
  color: var(--mm-color-text);
  line-height: var(--mm-line-height);
  -webkit-tap-highlight-color: var(--mm-color-background-emphasis);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
  text-decoration: none;
  color: inherit
}

[dir=rtl] .mm-menu {
  direction: rtl
}

.mm-panel {
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, .1);
  color: rgba(0, 0, 0, .75);
  z-index: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--mm-color-background);
  border-color: var(--mm-color-border);
  color: var(--mm-color-text);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  -o-transition: transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease
}

.mm-panel:after {
  height: 44px
}

.mm-panel:not(.mm-hidden) {
  display: block
}

.mm-panel:after {
  content: '';
  display: block;
  height: var(--mm-listitem-size)
}

.mm-panel_opened {
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.mm-panel_opened-parent {
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0)
}

.mm-panel_highest {
  z-index: 2
}

.mm-panel_noanimation {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important
}

.mm-panel_noanimation.mm-panel_opened-parent {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.mm-panels>.mm-panel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0
}

.mm-panel__content {
  padding: 20px 20px 0
}

.mm-panels {
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, .1);
  color: rgba(0, 0, 0, .75);
  position: relative;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
  background: var(--mm-color-background);
  border-color: var(--mm-color-border);
  color: var(--mm-color-text)
}

[dir=rtl] .mm-panel:not(.mm-panel_opened) {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0)
}

[dir=rtl] .mm-panel.mm-panel_opened-parent {
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0)
}

.mm-listitem_vertical>.mm-panel {
  display: none;
  width: 100%;
  padding: 10px 0 10px 10px;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important
}

.mm-listitem_vertical>.mm-panel:after,
.mm-listitem_vertical>.mm-panel:before {
  content: none;
  display: none
}

.mm-listitem_opened>.mm-panel {
  display: block
}

.mm-listitem_vertical>.mm-listitem__btn {
  height: 44px;
  height: var(--mm-listitem-size);
  bottom: auto
}

.mm-listitem_vertical .mm-listitem:last-child:after {
  border-color: transparent
}

.mm-listitem_opened>.mm-listitem__btn:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px
}

.mm-btn {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 50px;
  padding: 0
}

.mm-btn:after,
.mm-btn:before {
  border: 2px solid rgba(0, 0, 0, .3);
  border: 2px solid var(--mm-color-button)
}

.mm-btn_next:after,
.mm-btn_prev:before {
  content: '';
  border-bottom: none;
  border-right: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0
}

.mm-btn_prev:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto
}

.mm-btn_next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto
}

.mm-btn_close:after,
.mm-btn_close:before {
  content: '';
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.mm-btn_close:before {
  border-right: none;
  border-bottom: none;
  right: 18px
}

.mm-btn_close:after {
  border-left: none;
  border-top: none;
  right: 25px
}

[dir=rtl] .mm-btn_next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto
}

[dir=rtl] .mm-btn_prev:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto
}

[dir=rtl] .mm-btn_close:after,
[dir=rtl] .mm-btn_close:before {
  right: auto
}

[dir=rtl] .mm-btn_close:before {
  left: 25px
}

[dir=rtl] .mm-btn_close:after {
  left: 18px
}

.mm-navbar {
  min-height: 44px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  background: #f3f3f3;
  color: rgba(0, 0, 0, .3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: var(--mm-navbar-size);
  border-bottom: 1px solid var(--mm-color-border);
  background: var(--mm-color-background);
  color: var(--mm-color-text-dimmed);
  text-align: center;
  opacity: 1;
  -webkit-transition: opacity .4s ease;
  -o-transition: opacity .4s ease;
  transition: opacity .4s ease
}

.mm-navbar>* {
  min-height: 44px
}

@supports ((position: -webkit-sticky) or (position:sticky)) {
  .mm-navbar_sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1
  }
}

.mm-navbar>* {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.mm-navbar__btn {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0
}

.mm-navbar__title {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  padding-right: 20px
}

.mm-navbar__title:not(:last-child) {
  padding-right: 0
}

.mm-navbar__btn:not(.mm-hidden)+.mm-navbar__title {
  padding-left: 0
}

.mm-navbar__btn:not(.mm-hidden)+.mm-navbar__title:last-child {
  padding-right: 50px
}

[dir=rtl] .mm-navbar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse
}

.mm-listview {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0
}

.mm-listitem {
  color: rgba(0, 0, 0, .75);
  border-color: rgba(0, 0, 0, .1);
  color: var(--mm-color-text);
  border-color: var(--mm-color-border);
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.mm-listitem:after {
  content: '';
  border-color: inherit;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  left: 20px;
  right: 0;
  bottom: 0
}

.mm-listitem a,
.mm-listitem a:hover {
  text-decoration: none
}

.mm-listitem__btn,
.mm-listitem__text {
  padding: 12px;
  display: block;
  padding: calc((var(--mm-listitem-size) - var(--mm-line-height))/ 2);
  padding-left: 0;
  padding-right: 0;
  color: inherit
}

.mm-listitem__text {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 10px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%
}

.mm-listitem__btn {
  background: rgba(3, 2, 1, 0);
  border-color: inherit;
  width: auto;
  padding-right: 50px;
  position: relative
}

.mm-listitem__btn:not(.mm-listitem__text) {
  border-left-width: 1px;
  border-left-style: solid
}

.mm-listitem_selected>.mm-listitem__text {
  background: rgba(255, 255, 255, .4);
  background: var(--mm-color-background-emphasis)
}

.mm-listitem_opened>.mm-listitem__btn,
.mm-listitem_opened>.mm-panel {
  background: rgba(0, 0, 0, .05);
  background: var(--mm-color-background-highlight)
}

[dir=rtl] .mm-listitem:after {
  left: 0;
  right: 20px
}

[dir=rtl] .mm-listitem__text {
  padding-left: 10px;
  padding-right: 20px
}

[dir=rtl] .mm-listitem__btn {
  padding-left: 50px;
  border-left-width: 0;
  border-left-style: none
}

[dir=rtl] .mm-listitem__btn:not(.mm-listitem__text) {
  padding-right: 0;
  border-right-width: 1px;
  border-right-style: solid
}

.mm-page {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative
}

.mm-slideout {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  -o-transition: transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
  z-index: unset
}

.mm-wrapper_opened {
  overflow-x: hidden;
  position: relative
}

.mm-wrapper_opened .mm-page {
  min-height: 100vh
}

.mm-wrapper_background .mm-page {
  background: inherit
}

.mm-menu_offcanvas {
  position: fixed;
  right: auto;
  z-index: 0
}

.mm-menu_offcanvas:not(.mm-menu_opened) {
  display: none
}

.mm-menu_offcanvas {
  width: 80%;
  min-width: 240px;
  max-width: 440px
}

.mm-wrapper_opening .mm-menu_offcanvas~.mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_offcanvas~.mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0)
  }
}

@media all and (min-width: 550px) {
  .mm-wrapper_opening .mm-menu_offcanvas~.mm-slideout {
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0)
  }
}

.mm-wrapper__blocker {
  background: rgba(3, 2, 1, 0);
  overflow: hidden;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2
}

.mm-wrapper_blocking {
  overflow: hidden
}

.mm-wrapper_blocking body {
  overflow: hidden
}

.mm-wrapper_blocking .mm-wrapper__blocker {
  display: block
}

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  min-width: 1px !important;
  height: 1px !important;
  min-height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important
}

.mm-menu_autoheight:not(.mm-menu_offcanvas) {
  position: relative
}

.mm-menu_autoheight.mm-menu_position-bottom,
.mm-menu_autoheight.mm-menu_position-top {
  max-height: 80%
}

.mm-menu_autoheight-measuring .mm-panel {
  display: block !important
}

.mm-menu_autoheight-measuring .mm-panels>.mm-panel {
  bottom: auto !important;
  height: auto !important
}

.mm-menu_autoheight-measuring .mm-listitem_vertical:not(.mm-listitem_opened) .mm-panel {
  display: none !important
}

[class*=mm-menu_columns-] {
  -webkit-transition-property: width;
  -o-transition-property: width;
  transition-property: width
}

[class*=mm-menu_columns-] .mm-panels>.mm-panel {
  right: auto;
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  -o-transition-property: width, transform;
  transition-property: width, transform;
  transition-property: width, transform, -webkit-transform
}

[class*=mm-menu_columns-] .mm-panels>.mm-panel_opened,
[class*=mm-menu_columns-] .mm-panels>.mm-panel_opened-parent {
  display: block !important
}

[class*=mm-panel_columns-] {
  border-right: 1px solid;
  border-color: inherit
}

.mm-menu_columns-1 .mm-panel_columns-0,
.mm-menu_columns-2 .mm-panel_columns-1,
.mm-menu_columns-3 .mm-panel_columns-2,
.mm-menu_columns-4 .mm-panel_columns-3 {
  border-right: none
}

[class*=mm-menu_columns-] .mm-panels>.mm-panel_columns-0 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.mm-menu_columns-0 .mm-panels>.mm-panel {
  z-index: 0
}

.mm-menu_columns-0 .mm-panels>.mm-panel else {
  width: 100%
}

.mm-menu_columns-0 .mm-panels>.mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0)
}

.mm-menu_columns-0 {
  width: 80%;
  min-width: 240px;
  max-width: 0
}

.mm-wrapper_opening .mm-menu_columns-0~.mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-0~.mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0)
  }
}

@media all and (min-width: 0px) {
  .mm-wrapper_opening .mm-menu_columns-0~.mm-slideout {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

.mm-wrapper_opening .mm-menu_columns-0.mm-menu_position-right.mm-menu_opened~.mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-0.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0)
  }
}

@media all and (min-width: 0px) {
  .mm-wrapper_opening .mm-menu_columns-0.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

[class*=mm-menu_columns-] .mm-panels>.mm-panel_columns-1 {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0)
}

.mm-menu_columns-1 .mm-panels>.mm-panel {
  z-index: 1;
  width: 100%
}

.mm-menu_columns-1 .mm-panels>.mm-panel else {
  width: 100%
}

.mm-menu_columns-1 .mm-panels>.mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0)
}

.mm-menu_columns-1 {
  width: 80%;
  min-width: 240px;
  max-width: 440px
}

.mm-wrapper_opening .mm-menu_columns-1~.mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-1~.mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0)
  }
}

@media all and (min-width: 550px) {
  .mm-wrapper_opening .mm-menu_columns-1~.mm-slideout {
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0)
  }
}

.mm-wrapper_opening .mm-menu_columns-1.mm-menu_position-right.mm-menu_opened~.mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-1.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0)
  }
}

@media all and (min-width: 550px) {
  .mm-wrapper_opening .mm-menu_columns-1.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0)
  }
}

[class*=mm-menu_columns-] .mm-panels>.mm-panel_columns-2 {
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0)
}

.mm-menu_columns-2 .mm-panels>.mm-panel {
  z-index: 2;
  width: 50%
}

.mm-menu_columns-2 .mm-panels>.mm-panel else {
  width: 100%
}

.mm-menu_columns-2 .mm-panels>.mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0)
}

.mm-menu_columns-2 {
  width: 80%;
  min-width: 240px;
  max-width: 880px
}

.mm-wrapper_opening .mm-menu_columns-2~.mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-2~.mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0)
  }
}

@media all and (min-width: 1100px) {
  .mm-wrapper_opening .mm-menu_columns-2~.mm-slideout {
    -webkit-transform: translate3d(880px, 0, 0);
    transform: translate3d(880px, 0, 0)
  }
}

.mm-wrapper_opening .mm-menu_columns-2.mm-menu_position-right.mm-menu_opened~.mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-2.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0)
  }
}

@media all and (min-width: 1100px) {
  .mm-wrapper_opening .mm-menu_columns-2.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-880px, 0, 0);
    transform: translate3d(-880px, 0, 0)
  }
}

[class*=mm-menu_columns-] .mm-panels>.mm-panel_columns-3 {
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0)
}

.mm-menu_columns-3 .mm-panels>.mm-panel {
  z-index: 3;
  width: 33.34%
}

.mm-menu_columns-3 .mm-panels>.mm-panel else {
  width: 100%
}

.mm-menu_columns-3 .mm-panels>.mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0)
}

.mm-menu_columns-3 {
  width: 80%;
  min-width: 240px;
  max-width: 1320px
}

.mm-wrapper_opening .mm-menu_columns-3~.mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-3~.mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0)
  }
}

@media all and (min-width: 1650px) {
  .mm-wrapper_opening .mm-menu_columns-3~.mm-slideout {
    -webkit-transform: translate3d(1320px, 0, 0);
    transform: translate3d(1320px, 0, 0)
  }
}

.mm-wrapper_opening .mm-menu_columns-3.mm-menu_position-right.mm-menu_opened~.mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-3.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0)
  }
}

@media all and (min-width: 2050px) {
  .slideshow {
    height: 1200px !important;
  }
}

@media all and (min-width: 1650px) {
  .slideshow {
    height: 970px;
  }

  .mm-wrapper_opening .mm-menu_columns-3.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-1320px, 0, 0);
    transform: translate3d(-1320px, 0, 0)
  }
}

[class*=mm-menu_columns-] .mm-panels>.mm-panel_columns-4 {
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0)
}

.mm-menu_columns-4 .mm-panels>.mm-panel {
  z-index: 4;
  width: 25%
}

.mm-menu_columns-4 .mm-panels>.mm-panel else {
  width: 100%
}

.mm-menu_columns-4 .mm-panels>.mm-panel:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
  -webkit-transform: translate3d(500%, 0, 0);
  transform: translate3d(500%, 0, 0)
}

.mm-menu_columns-4 {
  width: 80%;
  min-width: 240px;
  max-width: 1760px
}

.mm-wrapper_opening .mm-menu_columns-4~.mm-slideout {
  -webkit-transform: translate3d(80vw, 0, 0);
  transform: translate3d(80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-4~.mm-slideout {
    -webkit-transform: translate3d(240px, 0, 0);
    transform: translate3d(240px, 0, 0)
  }
}

@media all and (min-width: 2200px) {
  .mm-wrapper_opening .mm-menu_columns-4~.mm-slideout {
    -webkit-transform: translate3d(1760px, 0, 0);
    transform: translate3d(1760px, 0, 0)
  }
}

.mm-wrapper_opening .mm-menu_columns-4.mm-menu_position-right.mm-menu_opened~.mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_columns-4.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0)
  }
}

@media all and (min-width: 2200px) {
  .mm-wrapper_opening .mm-menu_columns-4.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-1760px, 0, 0);
    transform: translate3d(-1760px, 0, 0)
  }
}

[class*=mm-menu_columns-].mm-menu_position-bottom,
[class*=mm-menu_columns-].mm-menu_position-top {
  width: 100%;
  max-width: 100%;
  min-width: 100%
}

.mm-wrapper_opening [class*=mm-menu_columns-].mm-menu_position-front {
  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
  transition-property: width, min-width, max-width, -webkit-transform;
  -o-transition-property: width, min-width, max-width, transform;
  transition-property: width, min-width, max-width, transform;
  transition-property: width, min-width, max-width, transform, -webkit-transform
}

.mm-counter {
  color: rgba(0, 0, 0, .3);
  display: block;
  padding-left: 20px;
  float: right;
  text-align: right;
  color: var(--mm-color-text-dimmed)
}

.mm-listitem_nosubitems>.mm-counter {
  display: none
}

[dir=rtl] .mm-counter {
  text-align: left;
  float: left;
  padding-left: 0;
  padding-right: 20px
}

.mm-divider {
  position: relative;
  min-height: 20px;
  padding: 4.3px;
  background: #f3f3f3;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: var(--mm-line-height);
  padding: calc(((var(--mm-listitem-size) * .65) - var(--mm-line-height)) * .5);
  padding-right: 10px;
  padding-left: 20px;
  font-size: 75%;
  text-transform: uppercase;
  background: var(--mm-color-background);
  opacity: 1;
  -webkit-transition: opacity .4s ease;
  -o-transition: opacity .4s ease;
  transition: opacity .4s ease
}

.mm-divider:before {
  background: rgba(0, 0, 0, .05)
}

@supports ((position: -webkit-sticky) or (position:sticky)) {
  .mm-divider {
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top: 0
  }

  .mm-navbar_sticky:not(.mm-hidden)~.mm-listview .mm-divider {
    top: var(--mm-navbar-size)
  }
}

.mm-divider:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: var(--mm-color-background-highlight)
}

.mm-wrapper_dragging .mm-menu,
.mm-wrapper_dragging .mm-slideout {
  -webkit-transition-duration: 0s !important;
  -o-transition-duration: 0s !important;
  transition-duration: 0s !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important
}

.mm-wrapper_dragging .mm-menu {
  pointer-events: none !important
}

.mm-wrapper_dragging .mm-wrapper__blocker {
  display: none !important
}

.mm-menu_dropdown {
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
  height: 80%
}

.mm-wrapper_dropdown .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0
}

.mm-wrapper_dropdown .mm-wrapper__blocker {
  -webkit-transition-delay: 0s !important;
  -o-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1
}

.mm-wrapper_dropdown .mm-menu_dropdown {
  z-index: 2
}

.mm-wrapper_dropdown.mm-wrapper_opened:not(.mm-wrapper_opening) .mm-menu_dropdown {
  display: none
}

.mm-menu_tip-bottom:before,
.mm-menu_tip-left:before,
.mm-menu_tip-right:before,
.mm-menu_tip-top:before {
  content: '';
  background: inherit;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: -1;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.mm-menu_tip-left:before {
  left: 22px
}

.mm-menu_tip-right:before {
  right: 22px
}

.mm-menu_tip-top:before {
  top: -8px
}

.mm-menu_tip-bottom:before {
  bottom: -8px
}

:root {
  --mm-iconbar-size: 50px
}

.mm-menu_iconbar-left .mm-navbars_bottom,
.mm-menu_iconbar-left .mm-navbars_top,
.mm-menu_iconbar-left .mm-panels {
  margin-left: 50px;
  margin-left: var(--mm-iconbar-size)
}

.mm-menu_iconbar-left .mm-iconbar {
  border-right-width: 1px;
  display: block;
  left: 0
}

.mm-menu_iconbar-right .mm-navbars_bottom,
.mm-menu_iconbar-right .mm-navbars_top,
.mm-menu_iconbar-right .mm-panels {
  margin-right: 50px;
  margin-right: var(--mm-iconbar-size)
}

.mm-menu_iconbar-right .mm-iconbar {
  border-left-width: 1px;
  display: block;
  right: 0
}

.mm-iconbar {
  width: 50px;
  border-color: rgba(0, 0, 0, .1);
  background: #f3f3f3;
  color: rgba(0, 0, 0, .3);
  display: none;
  width: var(--mm-iconbar-size);
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  border: 0 solid;
  border-color: var(--mm-color-border);
  background: var(--mm-color-background);
  color: var(--mm-color-text-dimmed);
  text-align: center
}

.mm-iconbar__bottom,
.mm-iconbar__top {
  width: inherit;
  position: absolute
}

.mm-iconbar__bottom>*,
.mm-iconbar__top>* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  padding: 12.5px 0
}

.mm-iconbar__bottom a,
.mm-iconbar__bottom a:hover,
.mm-iconbar__top a,
.mm-iconbar__top a:hover {
  text-decoration: none
}

.mm-iconbar__top {
  top: 0
}

.mm-iconbar__bottom {
  bottom: 0
}

.mm-iconbar__tab_selected {
  background: rgba(255, 255, 255, .4);
  background: var(--mm-color-background-emphasis)
}

:root {
  --mm-iconpanel-size: 50px
}

.mm-panel_iconpanel-1 {
  width: calc(100% - 50px);
  width: calc(100% - (var(--mm-iconpanel-size) * 1))
}

.mm-panel_iconpanel-2 {
  width: calc(100% - 100px);
  width: calc(100% - (var(--mm-iconpanel-size) * 2))
}

.mm-panel_iconpanel-3 {
  width: calc(100% - 150px);
  width: calc(100% - (var(--mm-iconpanel-size) * 3))
}

.mm-panel_iconpanel-first~.mm-panel {
  width: calc(100% - 50px);
  width: calc(100% - var(--mm-iconpanel-size))
}

.mm-menu_iconpanel .mm-panels>.mm-panel {
  left: auto;
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  -o-transition-property: transform, width;
  transition-property: transform, width;
  transition-property: transform, width, -webkit-transform
}

.mm-menu_iconpanel .mm-panels>.mm-panel_opened,
.mm-menu_iconpanel .mm-panels>.mm-panel_opened-parent {
  display: block !important
}

.mm-menu_iconpanel .mm-panels>.mm-panel_opened-parent {
  overflow-y: hidden;
  -webkit-transform: unset;
  -ms-transform: unset;
  transform: unset
}

.mm-menu_iconpanel .mm-panels>.mm-panel:not(.mm-panel_iconpanel-first):not(.mm-panel_iconpanel-0) {
  border-left-width: 1px;
  border-left-style: solid
}

.mm-menu_hidedivider .mm-panel_opened-parent .mm-divider,
.mm-menu_hidenavbar .mm-panel_opened-parent .mm-navbar {
  opacity: 0
}

.mm-panel__blocker {
  background: inherit;
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  -webkit-transition: opacity .4s ease;
  -o-transition: opacity .4s ease;
  transition: opacity .4s ease
}

.mm-panel_opened-parent .mm-panel__blocker {
  opacity: .6;
  bottom: -100000px
}

[dir=rtl] .mm-menu_iconpanel .mm-panels>.mm-panel {
  left: 0;
  right: auto;
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  -o-transition-property: transform, width;
  transition-property: transform, width;
  transition-property: transform, width, -webkit-transform
}

[dir=rtl] .mm-menu_iconpanel .mm-panels>.mm-panel:not(.mm-panel_iconpanel-first):not(.mm-panel_iconpanel-0) {
  border-left: none;
  border-right: 1px solid;
  border-color: inherit
}

.mm-menu_keyboardfocus a:focus,
.mm-menu_keyboardfocus.mm-menu_opened~.mm-wrapper__blocker a:focus {
  background: rgba(255, 255, 255, .4);
  background: var(--mm-color-background-emphasis);
  outline: 0
}

.mm-wrapper__blocker .mm-tabstart {
  cursor: default;
  display: block;
  width: 100%;
  height: 100%
}

.mm-wrapper__blocker .mm-tabend {
  opacity: 0;
  position: absolute;
  bottom: 0
}

.mm-navbars_top {
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.mm-navbars_top .mm-navbar:not(:last-child) {
  border-bottom: none
}

.mm-navbars_bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.mm-navbars_bottom .mm-navbar {
  border-bottom: none
}

.mm-navbars_bottom .mm-navbar:first-child {
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-top: 1px solid var(--mm-color-border)
}

.mm-btn:not(.mm-hidden)+.mm-navbar__searchfield .mm-searchfield__input {
  padding-left: 0
}

.mm-navbar__searchfield:not(:last-child) .mm-searchfield__input {
  padding-right: 0
}

.mm-navbar__breadcrumbs {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0 20px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

.mm-navbar__breadcrumbs>* {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-right: 6px
}

.mm-navbar__breadcrumbs>a {
  text-decoration: underline
}

.mm-navbar__breadcrumbs:not(:last-child) {
  padding-right: 0
}

.mm-btn:not(.mm-hidden)+.mm-navbar__breadcrumbs {
  padding-left: 0
}

.mm-navbar_tabs>* {
  padding: 0 10px;
  border: 1px solid transparent
}

.mm-navbar__tab_selected {
  background: #f3f3f3;
  color: rgba(0, 0, 0, .75);
  background: var(--mm-color-background);
  color: var(--mm-color-text)
}

.mm-navbar__tab_selected:not(:first-child) {
  border-left-color: rgba(0, 0, 0, .1)
}

.mm-navbar__tab_selected:not(:last-child) {
  border-right-color: rgba(0, 0, 0, .1)
}

.mm-navbar__tab_selected:not(:first-child) {
  border-left-color: var(--mm-color-border)
}

.mm-navbar__tab_selected:not(:last-child) {
  border-right-color: var(--mm-color-border)
}

.mm-navbars_top .mm-navbar_tabs {
  border-bottom: none
}

.mm-navbars_top .mm-navbar_tabs>* {
  border-bottom-color: rgba(0, 0, 0, .1);
  border-bottom-color: var(--mm-color-border)
}

.mm-navbars_top .mm-navbar__tab_selected {
  border-top-color: rgba(0, 0, 0, .1);
  border-top-color: var(--mm-color-border);
  border-bottom-color: transparent
}

.mm-navbars_top.mm-navbars_has-tabs .mm-navbar {
  background: rgba(255, 255, 255, .4);
  background: var(--mm-color-background-emphasis)
}

.mm-navbars_top.mm-navbars_has-tabs .mm-navbar_tabs~.mm-navbar {
  background: #f3f3f3;
  background: var(--mm-color-background)
}

.mm-navbars_bottom .mm-navbar_tabs:first-child {
  border-top: none
}

.mm-navbars_bottom .mm-navbar_tabs>* {
  border-top-color: rgba(0, 0, 0, .1);
  border-top-color: var(--mm-color-border)
}

.mm-navbars_bottom .mm-navbar__tab_selected {
  border-bottom-color: rgba(0, 0, 0, .1);
  border-bottom-color: var(--mm-color-border);
  border-top-color: transparent
}

.mm-navbars_bottom.mm-navbars_has-tabs .mm-navbar {
  background: #f3f3f3;
  background: var(--mm-color-background)
}

.mm-navbars_bottom.mm-navbars_has-tabs .mm-navbar_tabs,
.mm-navbars_bottom.mm-navbars_has-tabs .mm-navbar_tabs~.mm-navbar {
  background: rgba(255, 255, 255, .4);
  background: var(--mm-color-background-emphasis)
}

.mm-searchfield {
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: var(--mm-navbar-size);
  padding: 0;
  overflow: hidden
}

.mm-searchfield input {
  height: 30.8px;
  line-height: 30.8px
}

.mm-searchfield input,
.mm-searchfield input:focus,
.mm-searchfield input:hover {
  background: rgba(0, 0, 0, .05);
  color: rgba(0, 0, 0, .75)
}

.mm-searchfield input {
  display: block;
  width: 100%;
  max-width: 100%;
  height: calc(var(--mm-navbar-size) * .7);
  min-height: unset;
  max-height: unset;
  margin: 0;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none !important;
  border-radius: 4px;
  line-height: calc(var(--mm-navbar-size) * .7);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  font: inherit;
  font-size: inherit
}

.mm-searchfield input,
.mm-searchfield input:focus,
.mm-searchfield input:hover {
  background: var(--mm-color-background-highlight);
  color: var(--mm-color-text)
}

.mm-searchfield input::-ms-clear {
  display: none
}

.mm-searchfield__input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.mm-panel__noresultsmsg {
  color: rgba(0, 0, 0, .3);
  padding: 50px 0;
  color: var(--mm-color-text-dimmed);
  text-align: center;
  font-size: 150%
}

.mm-searchfield__btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0
}

.mm-panel_search {
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  border-left: none !important
}

.mm-searchfield__cancel {
  line-height: 44px;
  display: block;
  padding-right: 10px;
  margin-right: -100px;
  line-height: var(--mm-navbar-size);
  text-decoration: none;
  -webkit-transition: margin .4s ease;
  -o-transition: margin .4s ease;
  transition: margin .4s ease
}

.mm-searchfield__cancel-active {
  margin-right: 0
}

.mm-listitem_nosubitems>.mm-listitem__btn {
  display: none
}

.mm-listitem_nosubitems>.mm-listitem__text {
  padding-right: 10px
}

.mm-listitem_onlysubitems>.mm-listitem__text:not(.mm-listitem__btn) {
  z-index: -1;
  pointer-events: none
}

.mm-sectionindexer {
  background: inherit;
  text-align: center;
  font-size: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -20px;
  z-index: 5;
  -webkit-transition: right .4s ease;
  -o-transition: right .4s ease;
  transition: right .4s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly
}

.mm-sectionindexer a {
  color: rgba(0, 0, 0, .3);
  color: var(--mm-color-text-dimmed);
  line-height: 1;
  text-decoration: none;
  display: block
}

.mm-sectionindexer~.mm-panel {
  padding-right: 0
}

.mm-sectionindexer_active {
  right: 0
}

.mm-sectionindexer_active~.mm-panel {
  padding-right: 20px
}

.mm-menu_selected-hover .mm-listitem__btn,
.mm-menu_selected-hover .mm-listitem__text,
.mm-menu_selected-parent .mm-listitem__btn,
.mm-menu_selected-parent .mm-listitem__text {
  -webkit-transition: background-color .4s ease;
  -o-transition: background-color .4s ease;
  transition: background-color .4s ease
}

.mm-menu_selected-hover .mm-listview:hover>.mm-listitem_selected>.mm-listitem__text {
  background: 0 0
}

.mm-menu_selected-hover .mm-listitem__btn:hover,
.mm-menu_selected-hover .mm-listitem__text:hover {
  background: rgba(255, 255, 255, .4);
  background: var(--mm-color-background-emphasis)
}

.mm-menu_selected-parent .mm-panel_opened-parent .mm-listitem:not(.mm-listitem_selected-parent)>.mm-listitem__text {
  background: 0 0
}

.mm-menu_selected-parent .mm-listitem_selected-parent>.mm-listitem__btn,
.mm-menu_selected-parent .mm-listitem_selected-parent>.mm-listitem__text {
  background: rgba(255, 255, 255, .4);
  background: var(--mm-color-background-emphasis)
}

:root {
  --mm-sidebar-collapsed-size: 50px;
  --mm-sidebar-expanded-size: 440px
}

.mm-wrapper_sidebar-collapsed body,
.mm-wrapper_sidebar-expanded body {
  position: relative
}

.mm-wrapper_sidebar-collapsed .mm-slideout,
.mm-wrapper_sidebar-expanded .mm-slideout {
  -webkit-transition-property: width, -webkit-transform;
  transition-property: width, -webkit-transform;
  -o-transition-property: width, transform;
  transition-property: width, transform;
  transition-property: width, transform, -webkit-transform
}

.mm-wrapper_sidebar-collapsed .mm-page,
.mm-wrapper_sidebar-expanded .mm-page {
  background: inherit;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 100vh
}

.mm-wrapper_sidebar-collapsed .mm-menu_sidebar-collapsed,
.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  top: 0 !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important
}

.mm-wrapper_sidebar-collapsed .mm-slideout {
  width: calc(100% - 50px);
  -webkit-transform: translate3d(50px, 0, 0);
  transform: translate3d(50px, 0, 0);
  width: calc(100% - var(--mm-sidebar-collapsed-size));
  -webkit-transform: translate3d(var(--mm-sidebar-collapsed-size), 0, 0);
  transform: translate3d(var(--mm-sidebar-collapsed-size), 0, 0)
}

.mm-wrapper_sidebar-collapsed:not(.mm-wrapper_opening) .mm-menu_hidedivider .mm-divider,
.mm-wrapper_sidebar-collapsed:not(.mm-wrapper_opening) .mm-menu_hidenavbar .mm-navbar {
  opacity: 0
}

.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded {
  width: 440px;
  width: var(--mm-sidebar-expanded-size);
  min-width: 0 !important;
  max-width: 100000px !important;
  border-right-width: 1px;
  border-right-style: solid
}

.mm-wrapper_sidebar-expanded .mm-menu_sidebar-expanded.mm-menu_pageshadow:after {
  content: none;
  display: none
}

.mm-wrapper_sidebar-expanded.mm-wrapper_blocking,
.mm-wrapper_sidebar-expanded.mm-wrapper_blocking body {
  overflow: visible
}

.mm-wrapper_sidebar-expanded .mm-wrapper__blocker {
  display: none !important
}

.mm-wrapper_sidebar-expanded:not(.mm-wrapper_sidebar-closed) .mm-menu_sidebar-expanded.mm-menu_opened~.mm-slideout {
  width: calc(100% - 440px);
  -webkit-transform: translate3d(440px, 0, 0);
  transform: translate3d(440px, 0, 0);
  width: calc(100% - var(--mm-sidebar-expanded-size));
  -webkit-transform: translate3d(var(--mm-sidebar-expanded-size), 0, 0);
  transform: translate3d(var(--mm-sidebar-expanded-size), 0, 0)
}

.mm-menu__blocker {
  background: rgba(3, 2, 1, 0);
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3
}

.mm-menu_opened .mm-menu__blocker {
  display: none
}

[dir=rtl].mm-wrapper_sidebar-collapsed .mm-slideout {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

[dir=rtl].mm-wrapper_sidebar-expanded .mm-slideout {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

[dir=rtl].mm-wrapper_sidebar-expanded:not(.mm-wrapper_sidebar-closed) .mm-menu_sidebar-expanded.mm-menu_opened~.mm-slideout {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

input.mm-toggle {
  margin-top: 5px;
  background: rgba(0, 0, 0, .1);
  display: inline-block;
  min-width: 58px;
  width: 58px;
  height: 34px;
  margin: 0 10px;
  margin-top: calc((var(--mm-listitem-size) - 34px)/ 2);
  border: none !important;
  background: var(--mm-color-border);
  border-radius: 34px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  cursor: pointer;
  -webkit-transition: background-color .2s ease;
  -o-transition: background-color .2s ease;
  transition: background-color .2s ease
}

input.mm-toggle:before {
  background: #f3f3f3
}

input.mm-toggle:before {
  content: '';
  display: block;
  width: 32px;
  height: 32px;
  margin: 1px;
  border-radius: 34px;
  background: var(--mm-color-background);
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  -o-transition: transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease
}

input.mm-toggle:checked {
  background: #4bd963
}

input.mm-toggle:checked:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px)
}

input.mm-check {
  margin-top: 2px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: none !important;
  background: 0 0 !important;
  cursor: pointer;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  margin-top: calc((var(--mm-listitem-size) - 40px)/ 2)
}

input.mm-check:before {
  content: '';
  display: block;
  width: 40%;
  height: 20%;
  margin: 25% 0 0 20%;
  border-left: 3px solid;
  border-bottom: 3px solid;
  border-color: var(--mm-color-text);
  opacity: .3;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: opacity .2s ease;
  -o-transition: opacity .2s ease;
  transition: opacity .2s ease
}

input.mm-check:checked:before {
  opacity: 1
}

[dir=rtl] input.mm-toggle:checked~label.mm-toggle:before {
  float: left
}

.mm-menu_border-none .mm-listitem:after {
  content: none
}

.mm-menu_border-full .mm-listitem:after {
  left: 0 !important
}

.mm-menu_fx-menu-slide {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  -o-transition: transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease
}

.mm-wrapper_opened .mm-menu_fx-menu-slide {
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0)
}

.mm-wrapper_opening .mm-menu_fx-menu-slide {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.mm-wrapper_opened .mm-menu_fx-menu-slide.mm-menu_position-right {
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0)
}

.mm-wrapper_opening .mm-menu_fx-menu-slide.mm-menu_position-right {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.mm-menu_fx-panels-none .mm-panel,
.mm-panel_fx-none {
  -webkit-transition-property: none;
  -o-transition-property: none;
  transition-property: none
}

.mm-menu_fx-panels-none .mm-panel.mm-panel_opened-parent,
.mm-panel_fx-none.mm-panel_opened-parent {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.mm-menu_fx-panels-slide-0 .mm-panel_opened-parent {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.mm-menu_fx-panels-slide-100 .mm-panel_opened-parent {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0)
}

.mm-menu_fullscreen {
  width: 100%;
  min-width: 140px;
  max-width: 10000px
}

.mm-wrapper_opening .mm-menu_fullscreen~.mm-slideout {
  -webkit-transform: translate3d(100vw, 0, 0);
  transform: translate3d(100vw, 0, 0)
}

@media all and (max-width: 140px) {
  .mm-wrapper_opening .mm-menu_fullscreen~.mm-slideout {
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0)
  }
}

@media all and (min-width: 10000px) {
  .mm-wrapper_opening .mm-menu_fullscreen~.mm-slideout {
    -webkit-transform: translate3d(10000px, 0, 0);
    transform: translate3d(10000px, 0, 0)
  }
}

.mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened~.mm-slideout {
  -webkit-transform: translate3d(-100vw, 0, 0);
  transform: translate3d(-100vw, 0, 0)
}

@media all and (max-width: 140px) {
  .mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0)
  }
}

@media all and (min-width: 10000px) {
  .mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-10000px, 0, 0);
    transform: translate3d(-10000px, 0, 0)
  }
}

.mm-menu_fullscreen.mm-menu_position-top {
  height: 100vh;
  min-height: 140px;
  max-height: 10000px
}

.mm-menu_fullscreen.mm-menu_position-bottom {
  height: 100vh;
  min-height: 140px;
  max-height: 10000px
}

.mm-menu_listview-justify .mm-panels>.mm-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.mm-menu_listview-justify .mm-panels>.mm-panel:after {
  content: none;
  display: none
}

.mm-menu_listview-justify .mm-panels>.mm-panel .mm-listview {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0
}

.mm-menu_listview-justify .mm-panels>.mm-panel .mm-listitem {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  min-height: 50px
}

.mm-menu_listview-justify .mm-panels>.mm-panel .mm-listitem__text {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.mm-listview_inset {
  list-style: inside disc;
  width: 100%;
  padding: 0 30px 15px 30px;
  margin: 0
}

.mm-listview_inset .mm-listitem {
  padding: 5px 0
}

.mm-menu_multiline .mm-listitem__text {
  -o-text-overflow: clip;
  text-overflow: clip;
  white-space: normal
}

[class*=mm-menu_pagedim].mm-menu_opened~.mm-wrapper__blocker {
  opacity: 0
}

.mm-wrapper_opening [class*=mm-menu_pagedim].mm-menu_opened~.mm-wrapper__blocker {
  opacity: .3;
  -webkit-transition: opacity .4s ease .4s;
  -o-transition: opacity .4s ease .4s;
  transition: opacity .4s ease .4s
}

.mm-menu_opened.mm-menu_pagedim~.mm-wrapper__blocker {
  background: inherit
}

.mm-menu_opened.mm-menu_pagedim-black~.mm-wrapper__blocker {
  background: #000
}

.mm-menu_opened.mm-menu_pagedim-white~.mm-wrapper__blocker {
  background: #fff
}

.mm-menu_popup {
  -webkit-transition: opacity .4s ease;
  -o-transition: opacity .4s ease;
  transition: opacity .4s ease;
  opacity: 0;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
  height: 80%;
  min-height: 140px;
  max-height: 880px;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  z-index: 2;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0)
}

.mm-menu_popup.mm-menu_opened~.mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0
}

.mm-menu_popup.mm-menu_opened~.mm-wrapper__blocker {
  -webkit-transition-delay: 0s !important;
  -o-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1
}

.mm-wrapper_opening .mm-menu_popup {
  opacity: 1
}

.mm-menu_position-right {
  left: auto;
  right: 0
}

.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened~.mm-slideout {
  -webkit-transform: translate3d(-80vw, 0, 0);
  transform: translate3d(-80vw, 0, 0)
}

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0)
  }
}

@media all and (min-width: 550px) {
  .mm-wrapper_opening .mm-menu_position-right.mm-menu_opened~.mm-slideout {
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0)
  }
}

.mm-menu_position-bottom,
.mm-menu_position-front,
.mm-menu_position-top {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  -o-transition: transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease
}

.mm-menu_position-bottom.mm-menu_opened,
.mm-menu_position-front.mm-menu_opened,
.mm-menu_position-top.mm-menu_opened {
  z-index: 2
}

.mm-menu_position-bottom.mm-menu_opened~.mm-slideout,
.mm-menu_position-front.mm-menu_opened~.mm-slideout,
.mm-menu_position-top.mm-menu_opened~.mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0
}

.mm-menu_position-bottom.mm-menu_opened~.mm-wrapper__blocker,
.mm-menu_position-front.mm-menu_opened~.mm-wrapper__blocker,
.mm-menu_position-top.mm-menu_opened~.mm-wrapper__blocker {
  z-index: 1
}

.mm-menu_position-front {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0)
}

.mm-menu_position-front.mm-menu_position-right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0)
}

.mm-menu_position-bottom,
.mm-menu_position-top {
  width: 100%;
  min-width: 100%;
  max-width: 100%
}

.mm-menu_position-top {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0)
}

.mm-menu_position-top {
  height: 80vh;
  min-height: 140px;
  max-height: 880px
}

.mm-menu_position-bottom {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  top: auto
}

.mm-menu_position-bottom {
  height: 80vh;
  min-height: 140px;
  max-height: 880px
}

.mm-wrapper_opening .mm-menu_position-bottom,
.mm-wrapper_opening .mm-menu_position-front,
.mm-wrapper_opening .mm-menu_position-top {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.mm-menu_shadow-page:after {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  content: '';
  display: block;
  width: 20px;
  height: 120%;
  position: absolute;
  left: 100%;
  top: -10%;
  z-index: 100;
  -webkit-clip-path: polygon(-20px 0, 0 0, 0 100%, -20px 100%);
  clip-path: polygon(-20px 0, 0 0, 0 100%, -20px 100%);
  -webkit-box-shadow: var(--mm-shadow);
  box-shadow: var(--mm-shadow)
}

.mm-menu_shadow-page.mm-menu_position-right:after {
  left: auto;
  right: 100%;
  -webkit-clip-path: polygon(20px 0, 40px 0, 40px 100%, 20px 100%);
  clip-path: polygon(20px 0, 40px 0, 40px 100%, 20px 100%)
}

.mm-menu_shadow-page.mm-menu_position-front:after {
  content: none;
  display: none
}

.mm-menu_shadow-menu {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  -webkit-box-shadow: var(--mm-shadow);
  box-shadow: var(--mm-shadow)
}

.mm-menu_shadow-panels .mm-panels>.mm-panel {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  -webkit-box-shadow: var(--mm-shadow);
  box-shadow: var(--mm-shadow)
}

.mm-menu_tileview .mm-listview,
.mm-panel_tileview .mm-listview {
  margin: 0 !important
}

.mm-menu_tileview .mm-listview:after,
.mm-panel_tileview .mm-listview:after {
  content: '';
  display: block;
  clear: both
}

.mm-menu_tileview .mm-listitem,
.mm-panel_tileview .mm-listitem {
  padding: 0;
  float: left;
  position: relative;
  width: 50%;
  height: 0;
  padding-top: 50%
}

.mm-menu_tileview .mm-listitem:after,
.mm-panel_tileview .mm-listitem:after {
  left: 0;
  top: 0;
  border-right-width: 1px;
  border-right-style: solid;
  z-index: -1
}

.mm-menu_tileview .mm-listitem.mm-tile-xs,
.mm-panel_tileview .mm-listitem.mm-tile-xs {
  width: 12.5%;
  padding-top: 12.5%
}

.mm-menu_tileview .mm-listitem.mm-tile-s,
.mm-panel_tileview .mm-listitem.mm-tile-s {
  width: 25%;
  padding-top: 25%
}

.mm-menu_tileview .mm-listitem.mm-tile-l,
.mm-panel_tileview .mm-listitem.mm-tile-l {
  width: 75%;
  padding-top: 75%
}

.mm-menu_tileview .mm-listitem.mm-tile-xl,
.mm-panel_tileview .mm-listitem.mm-tile-xl {
  width: 100%;
  padding-top: 100%
}

.mm-menu_tileview .mm-listitem__text,
.mm-panel_tileview .mm-listitem__text {
  line-height: 1px;
  text-align: center;
  padding: 50% 10px 0 10px;
  margin: 0;
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 0
}

.mm-menu_tileview .mm-listitem__btn,
.mm-panel_tileview .mm-listitem__btn {
  width: auto
}

.mm-menu_tileview .mm-listitem__btn:after,
.mm-menu_tileview .mm-listitem__btn:before,
.mm-panel_tileview .mm-listitem__btn:after,
.mm-panel_tileview .mm-listitem__btn:before {
  content: none;
  display: none
}

.mm-menu_tileview .mm-divider,
.mm-panel_tileview .mm-divider {
  display: none
}

.mm-menu_tileview .mm-panel,
.mm-panel_tileview {
  padding-left: 0;
  padding-right: 0
}

.mm-menu_tileview .mm-panel:after,
.mm-menu_tileview .mm-panel:before,
.mm-panel_tileview:after,
.mm-panel_tileview:before {
  content: none;
  display: none
}

body.modal-open .mm-slideout {
  z-index: unset
}

@charset "UTF-8";

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.animate__animated.animate__repeat-1 {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat)
}

.animate__animated.animate__repeat-2 {
  -webkit-animation-iteration-count: 2;
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat)*2);
  animation-iteration-count: calc(var(--animate-repeat)*2)
}

.animate__animated.animate__repeat-3 {
  -webkit-animation-iteration-count: 3;
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat)*3);
  animation-iteration-count: calc(var(--animate-repeat)*3)
}

.animate__animated.animate__delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay)
}

.animate__animated.animate__delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay)*2);
  animation-delay: calc(var(--animate-delay)*2)
}

.animate__animated.animate__delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay)*3);
  animation-delay: calc(var(--animate-delay)*3)
}

.animate__animated.animate__delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay)*4);
  animation-delay: calc(var(--animate-delay)*4)
}

.animate__animated.animate__delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay)*5);
  animation-delay: calc(var(--animate-delay)*5)
}

.animate__animated.animate__faster {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration)/2);
  animation-duration: calc(var(--animate-duration)/2)
}

.animate__animated.animate__fast {
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration)*0.8);
  animation-duration: calc(var(--animate-duration)*0.8)
}

.animate__animated.animate__slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration)*2);
  animation-duration: calc(var(--animate-duration)*2)
}

.animate__animated.animate__slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration)*3);
  animation-duration: calc(var(--animate-duration)*3)
}

@media (prefers-reduced-motion:reduce),
print {
  .animate__animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important
  }

  .animate__animated[class*=Out] {
    opacity: 0
  }
}

@-webkit-keyframes bounce {

  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1)
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05)
  }

  80% {
    -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0) scaleY(.95);
    transform: translateZ(0) scaleY(.95)
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02)
  }
}

@keyframes bounce {

  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1)
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05)
  }

  80% {
    -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    -webkit-transform: translateZ(0) scaleY(.95);
    transform: translateZ(0) scaleY(.95)
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02)
  }
}

.animate__bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom
}

@-webkit-keyframes flash {

  0%,
  50%,
  to {
    opacity: 1
  }

  25%,
  75% {
    opacity: 0
  }
}

@keyframes flash {

  0%,
  50%,
  to {
    opacity: 1
  }

  25%,
  75% {
    opacity: 0
  }
}

.animate__flash {
  -webkit-animation-name: flash;
  animation-name: flash
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out
}

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  30% {
    -webkit-transform: scale3d(1.25, .75, 1);
    transform: scale3d(1.25, .75, 1)
  }

  40% {
    -webkit-transform: scale3d(.75, 1.25, 1);
    transform: scale3d(.75, 1.25, 1)
  }

  50% {
    -webkit-transform: scale3d(1.15, .85, 1);
    transform: scale3d(1.15, .85, 1)
  }

  65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1)
  }

  75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  30% {
    -webkit-transform: scale3d(1.25, .75, 1);
    transform: scale3d(1.25, .75, 1)
  }

  40% {
    -webkit-transform: scale3d(.75, 1.25, 1);
    transform: scale3d(.75, 1.25, 1)
  }

  50% {
    -webkit-transform: scale3d(1.15, .85, 1);
    transform: scale3d(1.15, .85, 1)
  }

  65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1)
  }

  75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

.animate__rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand
}

@-webkit-keyframes shakeX {

  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0)
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0)
  }
}

@keyframes shakeX {

  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0)
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0)
  }
}

.animate__shakeX {
  -webkit-animation-name: shakeX;
  animation-name: shakeX
}

@-webkit-keyframes shakeY {

  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }
}

@keyframes shakeY {

  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }
}

.animate__shakeY {
  -webkit-animation-name: shakeY;
  animation-name: shakeY
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg)
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg)
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg)
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg)
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg)
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg)
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg)
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg)
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

.animate__headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }

  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }

  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg)
  }

  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }

  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }

  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg)
  }

  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg)
  }

  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

.animate__swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  10%,
  20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate(-3deg);
    transform: scale3d(.9, .9, .9) rotate(-3deg)
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg)
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  10%,
  20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate(-3deg);
    transform: scale3d(.9, .9, .9) rotate(-3deg)
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg)
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

.animate__tada {
  -webkit-animation-name: tada;
  animation-name: tada
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
    transform: translate3d(-25%, 0, 0) rotate(-5deg)
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
    transform: translate3d(20%, 0, 0) rotate(3deg)
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
    transform: translate3d(-15%, 0, 0) rotate(-3deg)
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
    transform: translate3d(10%, 0, 0) rotate(2deg)
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
    transform: translate3d(-5%, 0, 0) rotate(-1deg)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
    transform: translate3d(-25%, 0, 0) rotate(-5deg)
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
    transform: translate3d(20%, 0, 0) rotate(3deg)
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
    transform: translate3d(-15%, 0, 0) rotate(-3deg)
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
    transform: translate3d(10%, 0, 0) rotate(2deg)
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
    transform: translate3d(-5%, 0, 0) rotate(-1deg)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble
}

@-webkit-keyframes jello {

  0%,
  11.1%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg)
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg)
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg)
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg)
  }

  66.6% {
    -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
    transform: skewX(-.78125deg) skewY(-.78125deg)
  }

  77.7% {
    -webkit-transform: skewX(.390625deg) skewY(.390625deg);
    transform: skewX(.390625deg) skewY(.390625deg)
  }

  88.8% {
    -webkit-transform: skewX(-.1953125deg) skewY(-.1953125deg);
    transform: skewX(-.1953125deg) skewY(-.1953125deg)
  }
}

@keyframes jello {

  0%,
  11.1%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg)
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg)
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg)
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg)
  }

  66.6% {
    -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
    transform: skewX(-.78125deg) skewY(-.78125deg)
  }

  77.7% {
    -webkit-transform: skewX(.390625deg) skewY(.390625deg);
    transform: skewX(.390625deg) skewY(.390625deg)
  }

  88.8% {
    -webkit-transform: skewX(-.1953125deg) skewY(-.1953125deg);
    transform: skewX(-.1953125deg) skewY(-.1953125deg)
  }
}

.animate__jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.animate__heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration)*1.3);
  animation-duration: calc(var(--animate-duration)*1.3);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out
}

@-webkit-keyframes backInDown {
  0% {
    -webkit-transform: translateY(-1200px) scale(.7);
    transform: translateY(-1200px) scale(.7);
    opacity: .7
  }

  80% {
    -webkit-transform: translateY(0) scale(.7);
    transform: translateY(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

@keyframes backInDown {
  0% {
    -webkit-transform: translateY(-1200px) scale(.7);
    transform: translateY(-1200px) scale(.7);
    opacity: .7
  }

  80% {
    -webkit-transform: translateY(0) scale(.7);
    transform: translateY(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

.animate__backInDown {
  -webkit-animation-name: backInDown;
  animation-name: backInDown
}

@-webkit-keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-2000px) scale(.7);
    transform: translateX(-2000px) scale(.7);
    opacity: .7
  }

  80% {
    -webkit-transform: translateX(0) scale(.7);
    transform: translateX(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

@keyframes backInLeft {
  0% {
    -webkit-transform: translateX(-2000px) scale(.7);
    transform: translateX(-2000px) scale(.7);
    opacity: .7
  }

  80% {
    -webkit-transform: translateX(0) scale(.7);
    transform: translateX(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

.animate__backInLeft {
  -webkit-animation-name: backInLeft;
  animation-name: backInLeft
}

@-webkit-keyframes backInRight {
  0% {
    -webkit-transform: translateX(2000px) scale(.7);
    transform: translateX(2000px) scale(.7);
    opacity: .7
  }

  80% {
    -webkit-transform: translateX(0) scale(.7);
    transform: translateX(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

@keyframes backInRight {
  0% {
    -webkit-transform: translateX(2000px) scale(.7);
    transform: translateX(2000px) scale(.7);
    opacity: .7
  }

  80% {
    -webkit-transform: translateX(0) scale(.7);
    transform: translateX(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

.animate__backInRight {
  -webkit-animation-name: backInRight;
  animation-name: backInRight
}

@-webkit-keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(.7);
    transform: translateY(1200px) scale(.7);
    opacity: .7
  }

  80% {
    -webkit-transform: translateY(0) scale(.7);
    transform: translateY(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

@keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(.7);
    transform: translateY(1200px) scale(.7);
    opacity: .7
  }

  80% {
    -webkit-transform: translateY(0) scale(.7);
    transform: translateY(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

.animate__backInUp {
  -webkit-animation-name: backInUp;
  animation-name: backInUp
}

@-webkit-keyframes backOutDown {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  20% {
    -webkit-transform: translateY(0) scale(.7);
    transform: translateY(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: translateY(700px) scale(.7);
    transform: translateY(700px) scale(.7);
    opacity: .7
  }
}

@keyframes backOutDown {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  20% {
    -webkit-transform: translateY(0) scale(.7);
    transform: translateY(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: translateY(700px) scale(.7);
    transform: translateY(700px) scale(.7);
    opacity: .7
  }
}

.animate__backOutDown {
  -webkit-animation-name: backOutDown;
  animation-name: backOutDown
}

@-webkit-keyframes backOutLeft {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  20% {
    -webkit-transform: translateX(0) scale(.7);
    transform: translateX(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: translateX(-2000px) scale(.7);
    transform: translateX(-2000px) scale(.7);
    opacity: .7
  }
}

@keyframes backOutLeft {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  20% {
    -webkit-transform: translateX(0) scale(.7);
    transform: translateX(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: translateX(-2000px) scale(.7);
    transform: translateX(-2000px) scale(.7);
    opacity: .7
  }
}

.animate__backOutLeft {
  -webkit-animation-name: backOutLeft;
  animation-name: backOutLeft
}

@-webkit-keyframes backOutRight {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  20% {
    -webkit-transform: translateX(0) scale(.7);
    transform: translateX(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: translateX(2000px) scale(.7);
    transform: translateX(2000px) scale(.7);
    opacity: .7
  }
}

@keyframes backOutRight {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  20% {
    -webkit-transform: translateX(0) scale(.7);
    transform: translateX(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: translateX(2000px) scale(.7);
    transform: translateX(2000px) scale(.7);
    opacity: .7
  }
}

.animate__backOutRight {
  -webkit-animation-name: backOutRight;
  animation-name: backOutRight
}

@-webkit-keyframes backOutUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  20% {
    -webkit-transform: translateY(0) scale(.7);
    transform: translateY(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: translateY(-700px) scale(.7);
    transform: translateY(-700px) scale(.7);
    opacity: .7
  }
}

@keyframes backOutUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  20% {
    -webkit-transform: translateY(0) scale(.7);
    transform: translateY(0) scale(.7);
    opacity: .7
  }

  to {
    -webkit-transform: translateY(-700px) scale(.7);
    transform: translateY(-700px) scale(.7);
    opacity: .7
  }
}

.animate__backOutUp {
  -webkit-animation-name: backOutUp;
  animation-name: backOutUp
}

@-webkit-keyframes bounceIn {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1)
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9)
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03)
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97)
  }

  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

@keyframes bounceIn {

  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1)
  }

  40% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9)
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03)
  }

  80% {
    -webkit-transform: scale3d(.97, .97, .97);
    transform: scale3d(.97, .97, .97)
  }

  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

.animate__bounceIn {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration)*0.75);
  animation-duration: calc(var(--animate-duration)*0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn
}

@-webkit-keyframes bounceInDown {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(.9);
    transform: translate3d(0, 25px, 0) scaleY(.9)
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(.95);
    transform: translate3d(0, -10px, 0) scaleY(.95)
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(.985);
    transform: translate3d(0, 5px, 0) scaleY(.985)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes bounceInDown {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(.9);
    transform: translate3d(0, 25px, 0) scaleY(.9)
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(.95);
    transform: translate3d(0, -10px, 0) scaleY(.95)
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(.985);
    transform: translate3d(0, 5px, 0) scaleY(.985)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown
}

@-webkit-keyframes bounceInLeft {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
    transform: translate3d(-3000px, 0, 0) scaleX(3)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
    transform: translate3d(25px, 0, 0) scaleX(1)
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0) scaleX(.98);
    transform: translate3d(-10px, 0, 0) scaleX(.98)
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0) scaleX(.995);
    transform: translate3d(5px, 0, 0) scaleX(.995)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes bounceInLeft {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
    transform: translate3d(-3000px, 0, 0) scaleX(3)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
    transform: translate3d(25px, 0, 0) scaleX(1)
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0) scaleX(.98);
    transform: translate3d(-10px, 0, 0) scaleX(.98)
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0) scaleX(.995);
    transform: translate3d(5px, 0, 0) scaleX(.995)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft
}

@-webkit-keyframes bounceInRight {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1)
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(.98);
    transform: translate3d(10px, 0, 0) scaleX(.98)
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(.995);
    transform: translate3d(-5px, 0, 0) scaleX(.995)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes bounceInRight {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1)
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(.98);
    transform: translate3d(10px, 0, 0) scaleX(.98)
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(.995);
    transform: translate3d(-5px, 0, 0) scaleX(.995)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight
}

@-webkit-keyframes bounceInUp {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(.9);
    transform: translate3d(0, -20px, 0) scaleY(.9)
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(.95);
    transform: translate3d(0, 10px, 0) scaleY(.95)
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(.985);
    transform: translate3d(0, -5px, 0) scaleY(.985)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes bounceInUp {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(.9);
    transform: translate3d(0, -20px, 0) scaleY(.9)
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(.95);
    transform: translate3d(0, 10px, 0) scaleY(.95)
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(.985);
    transform: translate3d(0, -5px, 0) scaleY(.985)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9)
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1)
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9)
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1)
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }
}

.animate__bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration)*0.75);
  animation-duration: calc(var(--animate-duration)*0.75);
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(.985);
    transform: translate3d(0, 10px, 0) scaleY(.985)
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(.9);
    transform: translate3d(0, -20px, 0) scaleY(.9)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
    transform: translate3d(0, 2000px, 0) scaleY(3)
  }
}

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(.985);
    transform: translate3d(0, 10px, 0) scaleY(.985)
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(.9);
    transform: translate3d(0, -20px, 0) scaleY(.9)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0) scaleY(3);
    transform: translate3d(0, 2000px, 0) scaleY(3)
  }
}

.animate__bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0) scaleX(.9);
    transform: translate3d(20px, 0, 0) scaleX(.9)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0) scaleX(2);
    transform: translate3d(-2000px, 0, 0) scaleX(2)
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0) scaleX(.9);
    transform: translate3d(20px, 0, 0) scaleX(.9)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0) scaleX(2);
    transform: translate3d(-2000px, 0, 0) scaleX(2)
  }
}

.animate__bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0) scaleX(.9);
    transform: translate3d(-20px, 0, 0) scaleX(.9)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
    transform: translate3d(2000px, 0, 0) scaleX(2)
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0) scaleX(.9);
    transform: translate3d(-20px, 0, 0) scaleX(.9)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
    transform: translate3d(2000px, 0, 0) scaleX(2)
  }
}

.animate__bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(.985);
    transform: translate3d(0, -10px, 0) scaleY(.985)
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0) scaleY(.9);
    transform: translate3d(0, 20px, 0) scaleY(.9)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0) scaleY(3);
    transform: translate3d(0, -2000px, 0) scaleY(3)
  }
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(.985);
    transform: translate3d(0, -10px, 0) scaleY(.985)
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0) scaleY(.9);
    transform: translate3d(0, 20px, 0) scaleY(.9)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0) scaleY(3);
    transform: translate3d(0, -2000px, 0) scaleY(3)
  }
}

.animate__bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig
}

@-webkit-keyframes fadeInTopLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInTopLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInTopLeft {
  -webkit-animation-name: fadeInTopLeft;
  animation-name: fadeInTopLeft
}

@-webkit-keyframes fadeInTopRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInTopRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInTopRight {
  -webkit-animation-name: fadeInTopRight;
  animation-name: fadeInTopRight
}

@-webkit-keyframes fadeInBottomLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInBottomLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInBottomLeft {
  -webkit-animation-name: fadeInBottomLeft;
  animation-name: fadeInBottomLeft
}

@-webkit-keyframes fadeInBottomRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInBottomRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInBottomRight {
  -webkit-animation-name: fadeInBottomRight;
  animation-name: fadeInBottomRight
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.animate__fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
}

.animate__fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0)
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0)
  }
}

.animate__fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

.animate__fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0)
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0)
  }
}

.animate__fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
}

.animate__fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0)
  }
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0)
  }
}

.animate__fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }
}

.animate__fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0)
  }
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0)
  }
}

.animate__fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig
}

@-webkit-keyframes fadeOutTopLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0)
  }
}

@keyframes fadeOutTopLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0)
  }
}

.animate__fadeOutTopLeft {
  -webkit-animation-name: fadeOutTopLeft;
  animation-name: fadeOutTopLeft
}

@-webkit-keyframes fadeOutTopRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0)
  }
}

@keyframes fadeOutTopRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0)
  }
}

.animate__fadeOutTopRight {
  -webkit-animation-name: fadeOutTopRight;
  animation-name: fadeOutTopRight
}

@-webkit-keyframes fadeOutBottomRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0)
  }
}

@keyframes fadeOutBottomRight {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 100%, 0);
    transform: translate3d(100%, 100%, 0)
  }
}

.animate__fadeOutBottomRight {
  -webkit-animation-name: fadeOutBottomRight;
  animation-name: fadeOutBottomRight
}

@-webkit-keyframes fadeOutBottomLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0)
  }
}

@keyframes fadeOutBottomLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 100%, 0);
    transform: translate3d(-100%, 100%, 0)
  }
}

.animate__fadeOutBottomLeft {
  -webkit-animation-name: fadeOutBottomLeft;
  animation-name: fadeOutBottomLeft
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
  }

  40% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
  }

  50% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  80% {
    -webkit-transform: perspective(400px) scale3d(.95, .95, .95) translateZ(0) rotateY(0deg);
    transform: perspective(400px) scale3d(.95, .95, .95) translateZ(0) rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  to {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }
}

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
  }

  40% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
  }

  50% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  80% {
    -webkit-transform: perspective(400px) scale3d(.95, .95, .95) translateZ(0) rotateY(0deg);
    transform: perspective(400px) scale3d(.95, .95, .95) translateZ(0) rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  to {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }
}

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1
  }

  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg)
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1
  }

  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg)
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }
}

.animate__flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-20deg);
    transform: perspective(400px) rotateY(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  60% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
    opacity: 1
  }

  80% {
    -webkit-transform: perspective(400px) rotateY(-5deg);
    transform: perspective(400px) rotateY(-5deg)
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-20deg);
    transform: perspective(400px) rotateY(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  60% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
    opacity: 1
  }

  80% {
    -webkit-transform: perspective(400px) rotateY(-5deg);
    transform: perspective(400px) rotateY(-5deg)
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }
}

.animate__flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }

  30% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1
  }

  to {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
}

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }

  30% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1
  }

  to {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
}

.animate__flipOutX {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration)*0.75);
  animation-duration: calc(var(--animate-duration)*0.75);
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }

  30% {
    -webkit-transform: perspective(400px) rotateY(-15deg);
    transform: perspective(400px) rotateY(-15deg);
    opacity: 1
  }

  to {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0
  }
}

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }

  30% {
    -webkit-transform: perspective(400px) rotateY(-15deg);
    transform: perspective(400px) rotateY(-15deg);
    opacity: 1
  }

  to {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0
  }
}

.animate__flipOutY {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration)*0.75);
  animation-duration: calc(var(--animate-duration)*0.75);
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY
}

@-webkit-keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__lightSpeedInRight {
  -webkit-animation-name: lightSpeedInRight;
  animation-name: lightSpeedInRight;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out
}

@-webkit-keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
    opacity: 0
  }

  60% {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    opacity: 1
  }

  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(30deg);
    transform: translate3d(-100%, 0, 0) skewX(30deg);
    opacity: 0
  }

  60% {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    opacity: 1
  }

  80% {
    -webkit-transform: skewX(5deg);
    transform: skewX(5deg)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__lightSpeedInLeft {
  -webkit-animation-name: lightSpeedInLeft;
  animation-name: lightSpeedInLeft;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out
}

@-webkit-keyframes lightSpeedOutRight {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0
  }
}

@keyframes lightSpeedOutRight {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0
  }
}

.animate__lightSpeedOutRight {
  -webkit-animation-name: lightSpeedOutRight;
  animation-name: lightSpeedOutRight;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in
}

@-webkit-keyframes lightSpeedOutLeft {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
    transform: translate3d(-100%, 0, 0) skewX(-30deg);
    opacity: 0
  }
}

@keyframes lightSpeedOutLeft {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0) skewX(-30deg);
    transform: translate3d(-100%, 0, 0) skewX(-30deg);
    opacity: 0
  }
}

.animate__lightSpeedOutLeft {
  -webkit-animation-name: lightSpeedOutLeft;
  animation-name: lightSpeedOutLeft;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
  }
}

@keyframes rotateIn {
  0% {
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
  }
}

.animate__rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
  -webkit-transform-origin: center;
  transform-origin: center
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
  }
}

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
  }
}

.animate__rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
  }
}

@keyframes rotateInDownRight {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
  }
}

.animate__rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
  }
}

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
  }
}

.animate__rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
  }
}

@keyframes rotateInUpRight {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1
  }
}

.animate__rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom
}

@-webkit-keyframes rotateOut {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0
  }
}

@keyframes rotateOut {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0
  }
}

.animate__rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
  -webkit-transform-origin: center;
  transform-origin: center
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0
  }
}

@keyframes rotateOutDownLeft {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0
  }
}

.animate__rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0
  }
}

@keyframes rotateOutDownRight {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0
  }
}

.animate__rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0
  }
}

@keyframes rotateOutUpLeft {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0
  }
}

.animate__rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0
  }
}

@keyframes rotateOutUpRight {
  0% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0
  }
}

.animate__rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom
}

@-webkit-keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
  }

  20%,
  60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
  }

  40%,
  80% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0
  }
}

@keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
  }

  20%,
  60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
  }

  40%,
  80% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0
  }
}

.animate__hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration)*2);
  animation-duration: calc(var(--animate-duration)*2);
  -webkit-animation-name: hinge;
  animation-name: hinge;
  -webkit-transform-origin: top left;
  transform-origin: top left
}

@-webkit-keyframes jackInTheBox {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) rotate(30deg);
    transform: scale(.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg)
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes jackInTheBox {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1) rotate(30deg);
    transform: scale(.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg)
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg)
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.animate__jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox
}

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate(-120deg);
    transform: translate3d(-100%, 0, 0) rotate(-120deg)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate(-120deg);
    transform: translate3d(-100%, 0, 0) rotate(-120deg)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn
}

@-webkit-keyframes rollOut {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate(120deg);
    transform: translate3d(100%, 0, 0) rotate(120deg)
  }
}

@keyframes rollOut {
  0% {
    opacity: 1
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate(120deg);
    transform: translate3d(100%, 0, 0) rotate(120deg)
  }
}

.animate__rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  50% {
    opacity: 1
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  50% {
    opacity: 1
  }
}

.animate__zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn
}

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
    transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
    transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

.animate__zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown
}

@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
    transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
    transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
    transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
    transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

.animate__zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft
}

@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
    transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
    transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
    transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
    transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

.animate__zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight
}

@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
    transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
    transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
    transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
    transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

.animate__zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp
}

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  to {
    opacity: 0
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  to {
    opacity: 0
  }
}

.animate__zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
    transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
    transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
    transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
    transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

.animate__zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
    transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
    transform: scale(.1) translate3d(-2000px, 0, 0)
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
    transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: scale(.1) translate3d(-2000px, 0, 0);
    transform: scale(.1) translate3d(-2000px, 0, 0)
  }
}

.animate__zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
  -webkit-transform-origin: left center;
  transform-origin: left center
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
    transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
    transform: scale(.1) translate3d(2000px, 0, 0)
  }
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
    transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: scale(.1) translate3d(2000px, 0, 0);
    transform: scale(.1) translate3d(2000px, 0, 0)
  }
}

.animate__zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
  -webkit-transform-origin: right center;
  transform-origin: right center
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
    transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
    transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
    -webkit-animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    animation-timing-function: cubic-bezier(.175, .885, .32, 1)
  }
}

.animate__zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp
}

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
}

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
}

.animate__slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

.animate__slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
}

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
}

.animate__slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }
}

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }
}

.animate__slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp
}

/*  */
.image-post {
  height: 200px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  transition: transform 0.3s ease;
  transform: scale(1);
  cursor: pointer;
  aspect-ratio: 5/4;
}

.text-ellipsis-2-lines {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  /* Điều chỉnh độ cao dòng nếu cần */
  max-height: 2.4em;
  /* Chiều cao tối đa cho 2 dòng */
}

.slideshow {
  width: 100% !important;
  overflow: hidden;
}

.slideshow-item img {
  width: 100% !important;
  height: auto;
}


.img-slideshow {
  width: 100%;
  aspect-ratio: 1366 / 615;
  object-fit: cover;
}

.img-library {
  width: 100%;
  aspect-ratio: 1280 / 960;
  object-fit: cover;
}

@media only screen and (max-width:768px) {
  #my-menu {
    display: block;
  }
}

#my-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  transition: all 0.3s ease-in-out;
}

/* nav:not(.mm-menu) {
  display: none;

} */

.slideshow {
  /* height: 400px; */
}

.breadcrumb-item-custom {
  font-weight: bold;
}

.breadcrumb-item-custom .breadcrumb-link {
  text-decoration: none;
  color: #212529 !important;
}

.breadcrumb-item-custom .breadcrumb-link-select {
  text-decoration: none;
  color: #6C757D !important;
}

.breadcrumb-item a {
  text-decoration: none !important;
  color: #212529 !important;
}

.newsHot-contain {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.news-item-blog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #b4e8bb;
  border-radius: 50px;
  overflow: hidden;
  height: 200px;
}

.news-item-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.news-item-image,
.news-item-content {
  flex: 1;
  height: 100%;
  overflow: hidden;
  /* Đảm bảo nội dung không tràn ra ngoài */
}

.news-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.news-item-image img:hover {
  transform: scale(1.1);
  filter: brightness(1.1);
}

.news-item-content {
  background-color: #fff;
  padding: 32px 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}

.news-item-blog:hover .news-item-image,
.news-item-blog:hover .news-item-content {
  background-color: #b4e8bb;
}

.news-item-content h3 a {
  color: #252525 !important;
}

.news-item-content h3 a:hover {
  color: #f4941b !important;
}

.news-item-content h3 {
  margin-bottom: 10px;
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.news-item-content p {
  color: #6c757d;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.news-item-content p a {
  color: #252525;
  text-decoration: none;
}

.news-item-content p a:hover {
  text-decoration: underline;
}

.grid-view-library {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Chia thành 4 cột đều nhau */
  gap: 16px;
  /* Khoảng cách giữa các item */
}

.grid-item-library {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.library-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.study-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  background-color: #ceedd2;
  overflow: hidden;
  padding: 10px;
}

.study-item {
  display: grid;
  grid-template-columns: 1fr; /* Một cột duy nhất */
  grid-template-rows: auto 1fr; /* Row đầu tiên (title) tự động, row thứ hai chiếm phần còn lại */
  position: relative;
  height: 400px;
  background: url(../src/assets/image/mask_chuongtrinh_item.png) no-repeat center center;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50px;
  align-items: center;
}

.study-item:hover {
  background: url(../src/assets/image/mask_chuongtrinh_item_hover.png) no-repeat center center;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.study-item:hover .study-title a {
  color: #f4941b;
}

.study-item:hover .study-content .study-list ul {
  color: white;
}

.study-title {
  grid-row: 1; 
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.study-title a {
  font-family: Baloo;
  font-size: 26px;
  text-decoration: none;
}

.study-title a:hover {
  color: #f4941b;
}

.study-content {
  grid-row: 2;
  display: flex;
  flex-grow: 1;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 15px 40px;
  /* align-items: start; */
}

.study-list {
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: start;
}

.study-list ul {
  padding-left: 10px;
  list-style-type: disc;
}

.study-pic {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.study-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
