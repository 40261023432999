/* App.css hoặc một file CSS riêng biệt */
.sidebar {
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  z-index: 1000; /* Đảm bảo sidebar nằm trên các phần tử khác */
}

.sidebar.collapsed {
  width: 60px; /* Chiều rộng khi thu gọn */
}

.sidebar.collapsed .navbar-brand,
.sidebar.collapsed .nav-item span:first-child {
  display: none;
}

.sidebar.collapsed .nav-item {
  text-align: center;
}

.sidebar .nav-item span {
  font-size: 1.5rem; /* Điều chỉnh kích thước icon */
}

.content {
  margin-left: 240px; /* Chiều rộng mặc định của sidebar */
  transition: margin-left 0.3s;
}

.content.collapsed {
  margin-left: 60px; /* Chiều rộng khi sidebar thu gọn */
}

@media (max-width: 767px) {
  .sidebar {
    width: 240px; /* Chiều rộng khi sidebar mở trên điện thoại */
  }

  .content {
    margin-left: 240px; /* Khoảng cách để nội dung không bị đẩy ra ngoài trên màn hình nhỏ */
  }
}
